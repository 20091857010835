.dynamicSidebar {
    width: 310px;
    position: fixed;
    top: 0;
    right: -310px;
    height: 100vh;
    z-index: 1041;
    padding: 15px;
    background: $link-sidebar;
    transition: all 0.3s;
    overflow-y: auto;
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    &.active {
        right: 0;
    }

    .dynamicSidebarHeader {
        text-align: center;
        margin: 20px 0;
        h3 {
            font-size: 1.3125rem;
            color: #1e2022;
            margin-bottom: 3px;
        }
        p {
            font-size: 0.9rem;
            color: #677788;
            line-height: 1.1;
        }
    }
    .dynamicSidebarBody {
        margin: 40px 0;
        p {
            font-size: small;
            margin-bottom: 15px;
            a {
                color: $bg-sidebar;
                &:hover {
                    color: #ccc;
                }
            }
        }
        .divider {
            position: relative;
            display: inline-block;
            font-size: 0.75rem;
            color: #8c98a4;
            padding: 20px 10px;
            &::before,
            &::after {
                position: absolute;
                top: 50%;
                content: "";
            }
            &::before {
                right: 100%;
                background-image: linear-gradient(45deg, transparent, #bdc5d1);
                background-repeat: repeat-x;
            }
            &::after {
                left: 100%;
                background-image: linear-gradient(45deg, #bdc5d1, transparent);
                background-repeat: repeat-x;
            }
            a.btn-primary {
                color: #fff;
                &:hover,
                &:focus,
                &.active {
                    color: #fff;
                }
            }
        }

        .divider-xs::before,
        .divider-xs::after {
            width: 3rem;
            height: 0.0625rem;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
        .calc {
            text-align: right;
            .text {
                font-size: 0.85em;
            }
            .price {
                font-size: 1.5em;
            }
        }

        .card-courses-list {
            width: 100%;
            height: 80px;
            margin-bottom: 20px;
            padding: 5px;
            background: #f9f9f9;
            border: 2px solid transparent;
            .btn-remove {
                display: none;
            }
            &:hover {
                border: 2px solid var(--main-color);
                .btn-remove {
                    position: absolute;
                    top: -13px;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    background: var(--main-color);
                    .material-icons {
                        color: #fff;
                        font-size: 11px;
                        line-height: 1;
                    }
                }
            }
            img {
                width: 100%;
                height: 66px;
                object-fit: cover;
            }

            .details {
                h4 {
                    font-size: 13px;
                    text-transform: uppercase;
                    font-weight: 600;
                    margin-bottom: 5px;
                }
                p {
                    color: var(--main-color);
                    font-size: 15px;
                    font-weight: 600;
                }
            }
        }
    }

}

.sidebarHide {
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    color: $bg-sidebar !important;
    text-align: right;
    .material-icons {
        font-size: 1.2rem;
        cursor: pointer !important;
    }
}

.dynamicOverlay {
    display: none;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1035;
    opacity: 0;
    transition: all 0.5s ease-in-out;
    &.active {
        display: block;
        opacity: 1;
    }
}

@media (max-width: 767px) {
}
