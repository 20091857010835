

@media (max-width: 767px) {

    .banner-home {
        width: 100%;
        min-height: 180px !important;
        object-fit: cover !important;
    }

    // .card-style-2, .carousel-item-card {
    //     margin: 0;
    //     width: 100% !important;
    // }

    // .card-client {
      
    //     .cover {
    //         height: 260px !important;
    //     }
    //   }
}

