.sidemenu {
    width: 310px;
    position: fixed;
    top: 0;
    left: -310px;
    height: 100vh;
    z-index: 1036;
    background: $bg-sidebar;
    transition: all 0.3s ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
    &.active {
        margin-left: 310px;
    }
    .header {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 170px;
        padding: 0 10px 0 25px;
        img {
            width: 160px;
            height: auto;
        }
        h4 {
            color: $link-sidebar;
            font-size: 16px;
            font-weight: 500;
            margin: 15px 0 5px;
        }
        h5 {
            font-size: 14px;
            color: $link-sidebar;
            font-weight: 200;
            max-width: 25ch;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    ul {
        &.components {
            padding: 0;
        }
        li {
            a {
                padding: 12px 20px;
                font-size: 1rem !important;
                font-weight: 300;
                display: block;
                color: $link-sidebar;
                border-left: 4px solid transparent;
                text-decoration: none;
                &:hover {
                    color: $link-sidebar;
                    background: rgba(0, 0, 0, 0.3);
                    border-left: 4px solid $primary-color;
                    text-decoration: none;
                }
            }
            a:not([href]):not([tabindex]) {
                color: $link-sidebar;
            }
            .current {
                // color: #e9e9e9;
                background: rgba(0, 0, 0, 0.3);
                border-left: 4px solid $primary-color;
            }
        }
    }
}

.overlay {
    display: none;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;

    &.active {
        display: block;
        opacity: 1;
    }
}

.triangle {
    position: absolute;
    right: -1px;
    width: 0;
    height: 0;
    border-top: 40px solid rgba(0, 0, 0, 0.2);
    border-right: 40px solid rgba(0, 0, 0, 0.2);
    border-bottom: 40px solid transparent;
    border-left: 40px solid transparent;
    cursor: pointer;
    i {
        position: relative;
        top: -25px;
        right: -8px;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
    }
}

@media (max-width: 767px) {
    .sidemenu {
        width: 91%;
        height: 100vh;
        top: 0;
        left: -91%;
        z-index: 1060;

        &.active {
            margin-left: 91%;
        }
    }
    // .overlay { width: 100vw; height: 100vh; background: rgba(0, 0, 0, 0.7);   z-index: 998; }
}

.sidemenu .profile-sidebar {
    float: left;
    width: 100%;
    margin-top: 10px;
}

.sidemenu .profile-sidebar img {
    float: left;
    width: 20% !important;
    margin-right: 10px !important;
    margin-top: 5px;
    border: 3px solid #fff;
}

.sidemenu .profile-sidebar h4 {
    font-weight: bold !important;
}

.sidemenu .profile-sidebar a {
    color: #fff;
}
