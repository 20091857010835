@font-face {
    font-family: 'Poppins';
    font-weight: 100;
    font-display: swap;
    src: url(./Poppins-Thin.ttf) format('woff2');
  }
  @font-face {
    font-family: 'Poppins';
    font-weight: 300;
    font-display: swap;
    src: url(./Poppins-Light.ttf) format('woff2');
  }
  @font-face {
    font-family: 'Poppins';
    font-weight: 400;
    font-display: swap;
    src: url(./Poppins-Regular.ttf) format('woff2');
  }
  @font-face {
    font-family: 'Poppins';
    font-weight: 500;
    font-display: swap;
    src: url(./Poppins-Medium.ttf) format('woff2');
  }
  @font-face {
    font-family: 'Poppins';
    font-weight: 600;
    font-display: swap;
    src: url(./Poppins-Bold.ttf) format('woff2');
  }
  @font-face {
    font-family: 'Poppins';
    font-weight: 700;
    font-display: swap;
    src: url(./Poppins-Black.ttf) format('woff2');
  }