.table-plans {
  text-align: left;
  td {
    vertical-align: middle;
  }
  .fa-check {
    color: green;
  }
  .fa-close {
    color: #f30;
  }
}

.table-requests {
  background: #fff;
  font-size: 14px;
  padding: 10px;
  th {
    border-top: none $im;
  }
  td {
    padding-bottom: 10px;
    .product {
      float: left;
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
}

.table-custom {

  table {
    width: 100%;
    border-collapse: unset;
  }

  tr {
    background: transparent;
  }

  th,
  td {
    vertical-align: middle;
  }

  th {
    padding: 0;
    color: #666;
    font-weight: 600;
    font-size: 14px;
    background: transparent;
  }

  th.title {
    color: var(--main-color);
    font-size: 17px;
    text-transform: uppercase;
  }

  td {
    padding: 7px 10px;
    font-size: 14px;
    color: #555;
    background: #f6f6f6;

    &:first-child {
      border-left: 3px solid var(--main-color);
    }

    &.evaluation {
      padding: 0;
      background: transparent;
    }

    .border-left-light {
      border-left: 3px solid rgba(0, 0, 0, 0.1);
    }

    .avatar {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 10px;
    }

    a {
      color: #555;

      &:hover {
        color: var(--main-color);
      }
    }

    [data-toggle="collapse"]:after {
      float: none !important;
      margin-right: 0 !important;
      margin-top: 0 !important;
      font-size: 18px !important;
    }

    .fw-600 {
      font-weight: 600;
    }

    .fa-star {
      color: #bbb;

      &:hover,
      &.active {
        color: #f7b800;
      }
    }

    .fa-eye {
      font-size: 12px;
      color: #bbb;

      &:hover {
        color: var(--main-color);
      }
    }

    .dropdown {
      border-radius: 3px;

      .dropdown-menu {
        border: none;
        border-radius: 4px;
        padding: 0;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);

        .dropdown-menu-header {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          height: 140px;
          background: var(--main-color);
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding: 15px;

          .avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            -o-object-fit: cover;
            object-fit: cover;
            margin: 0 auto 10px !important;
          }
        }

        textarea {
          width: 100%;
          margin: 0;
          padding-right: 10px;
          padding-left: 10px;
          outline: 0;
          border-radius: 4px;
          font-size: 11px;
          border: 1px solid #eee;
          resize: none;
        }

        p.info {
          line-height: 1;
          font-size: 11px;
          font-weight: 500;
        }
      }
    }
  }

  td.no-border {
    border: 0 !important;
  }

  td.no-background {
    background: transparent;
  }

  .actions {
    width: 5%;
    text-align: center;
    font-size: 13px;

    i {
      color: var(--main-color);
    }
  }

  .w-5 {
    width: 5%;
  }

  .w-14 {
    width: 14%;
  }

  .status {
    font-size: 13px;
  }

  .status-success {
    background: #57a742;
    color: #fff;
  }

  .status-warning {
    background: #f7a225;
    color: #fff;
  }

  .status-danger {
    background: #ec4134;
    color: #fff;
  }

  .status-info {
    background: #007bff;
    color: #fff;
  }
}

.headerPanel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 80px;
  padding: 0 5px;

  h5 {
    font-weight: 500;
  }

}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  .table-custom {
    table,
    thead,
    tbody,
    tr,
    th,
    td {
      display: block;
    }

    table {
      border: none;
    }

    caption {
      padding: 5px 0;
      color: var(--main-color);
      font-weight: 600;
      text-transform: uppercase;
    }

    thead tr {
      display: none;
    }

    tr {
      margin: 0 0 1rem 0;
      border-left: 0 solid var(--main-color);
      background: #f9f9f9;

      &:nth-child(odd) {
        background: #f1f1f1;
      }
    }

    td {
      border: none;
      position: relative;
      padding-left: 50%;
      background: transparent;

      &:first-child {
        border-left: none;
      }

      &:before {
        position: absolute;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        vertical-align: middle;
      }
    }
  }
}
