.sectionLabFinanceiro {
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 70px;
    margin-bottom: 50px;
}

.sectionLabFinanceiro:last-child {
    border-bottom:0;
    padding-bottom: 0;
    margin-bottom: 20px;
}

.titleLabFinaceiro,
.paragraphLabFinanceiro {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;
}

.titleLabFinaceiro {
    font-size: 24px;
    color: #39a793;
    font-weight: 600;
}

.paragraphLabFinanceiro {
    font-size: 16px;
    color: #36393c;
}

.paragraphLabFinanceiro span {
    color: #055e63;
    font-weight: 600 !important;
}

.linkLabFinanceiro {
    color: #f4f4f4;
    background: #055e63;
    text-transform: uppercase;
    font-weight: 500;
    padding: 12px 30px;
    margin: 30px 0;
}

.linkLabFinanceiro:hover {
    margin-top: 10px;
    transition: all .3s;
    text-decoration: none;
    color: #fff;
    background: #39a793;
    cursor: pointer;
}

.socialslabFinanceiro {
    display: flex;
}

.socialslabFinanceiro .fa {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 20px;
    text-decoration: none;
    border-radius: 50%;
    margin: 15px 15px 0 0;
}

.socialslabFinanceiro .fa:hover {
    opacity: 0.7;
    color: #f7f7f7;
    cursor: pointer;
}

.socialslabFinanceiro .fa-facebook {
    background: #3B5998;
    color: #f7f7f7;
}

.socialslabFinanceiro .fa-twitter {
    background: #55ACEE;
    color: #f7f7f7;
}

.socialslabFinanceiro .fa-google {
    background: #dd4b39;
    color: #f7f7f7;
}

.socialslabFinanceiro .fa-linkedin {
    background: #007bb5;
    color: #f7f7f7;
}

.socialslabFinanceiro .fa-youtube {
    background: #bb0000;
    color: #f7f7f7;
}

.socialslabFinanceiro .fa-instagram {
    background: #125688;
    color: #f7f7f7;
}

.socialslabFinanceiro .fa-pinterest {
    background: #cb2027;
    color: #f7f7f7;
}

.socialslabFinanceiro .fa-vimeo {
    background: #45bbff;
    color: #f7f7f7;
}

.listLabFinanceiro {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    list-style: none;
}

.listLabFinanceiro li {
    margin: 5px 0;
}

.listLabFinanceiro strong {
    color: #055e63;
    font-weight: 600;
}

.listLabFinanceiro a {
    color: #055e63;
    font-weight: 600;
    text-decoration: none;
}

.listLabFinanceiro li a.linkLabFinanceiro {
    color: #f4f4f4 !important;
    text-decoration: none;
}



.text-orange {
    color: #f3742b !important;
    font-weight: 600;
}