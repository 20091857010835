
// MAT-CHECKBOX

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $primary-color;
}

.mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: $primary-color;
}

// MAT-SELECT

.cdk-overlay-container {
  z-index: 1050;
}

// MAT-TAB
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: $primary-color;
}

.mat-tab-body-content {
  padding: 20px 10px;
  overflow: hidden $im;  
 }

.mat-tab-list {
  display: inline-flex $im;
  justify-content: center $im;
  align-items: center $im;
}

.mat-tab-label, .mat-tab-link {
  text-transform: uppercase;
}

// MAT-INPUTS

.mat-form-field .mat-form-field-ripple  {
  background-color: $primary-color;
 }

.mat-form-field.mat-focused .mat-form-field-ripple  {
  background-color: $primary-color $im;
}

.mat-form-field .mat-form-field-label {
  color: #333 $im;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: $primary-color $im;
}

// MAT-MENU

.mat-button, .mat-flat-button, .mat-icon-button, .mat-stroked-button {
  outline: 0 $im;
  }

.mat-menu-panel {
  min-width: 200px $im;
  border-radius: 6px $im;
  outline: none;
  .mat-menu-content:not(:empty) {
      padding-top: 0;
      padding-bottom: 0;
  }
  .mat-menu-item {
    color: $primary-color;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    outline: none;
  }
}

// MAT RADIO GROUP
.radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.radio-button {
  margin: 5px;
}

// MAT stepper
.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
    background-color: $primary-color;
    color: #fff;
}

// MAT-SPINNER
.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  .mat-spinner, .mat-spinner svg {
    width: 50px $im;
    height: 50px $im;
  }
  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: $primary-color;
    stroke-dasharray: 282px $im;
    stroke-width: 7% $im;
  }
}
