	/*
  	Flaticon icon font: Flaticon
  	Creation date: 30/03/2020 15:42
  	*/

	@font-face {
	  font-family: "Flaticon";
	  src: url("./Flaticon.eot");
	  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
	    url("./Flaticon.woff2") format("woff2"),
	    url("./Flaticon.woff") format("woff"),
	    url("./Flaticon.ttf") format("truetype"),
	    url("./Flaticon.svg#Flaticon") format("svg");
	  font-weight: normal;
	  font-style: normal;
	}

	@media screen and (-webkit-min-device-pixel-ratio:0) {
	  @font-face {
	    font-family: "Flaticon";
	    src: url("./Flaticon.svg#Flaticon") format("svg");
	  }
	}

	[class^="flaticon-"]:before,
	[class*=" flaticon-"]:before,
	[class^="flaticon-"]:after,
	[class*=" flaticon-"]:after {
	  font-family: Flaticon;
	  font-style: normal;
	}

	.flaticon-trofeu:before {
	  content: "\f100";
	}

	.flaticon-classificacao:before {
	  content: "\f101";
	}

	.flaticon-tempo:before {
	  content: "\f102";
	}