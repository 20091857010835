// .home-component-search-form {
//   position: relative;
//   width: 650px;
//   height: auto;
//   margin: -30px auto 0;
//   padding: 0 $im;
//   .col-12, .col-sm-3 {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
//   .input-group {
//     min-width: 100%;
//   }
//   input[type=text] {
//     border-radius: 4px;
//     box-shadow: 1px 1px 2px #c9bfbb;
//     background: #f5f5f5;
//     border: transparent;
//     width:100%;
//     margin-top: 0;
//     padding: 2px 10px;
//   }
//   h3 {
//     font-weight: 600;
//     font-style: italic;
//     font-size: 26px;
//   }
// }

.custom-mat-option {
    height: auto !important;
    line-height: 20px !important;
    white-space: pre-wrap; /* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap; /* Opera <7 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* IE */
    p {
        margin-top: 7px;
        &.title {
            text-transform: uppercase;
            font-weight: 500;
        }
    }
}

.custom-mat-option-image {
    padding: 10px 10px 5px;
    margin-right: 0;
}

.custom-mat-option-image img {
    width: 58px;
    height: 58px;
    object-fit: cover;
}

.home-component-search-form {
    position: relative;
    width: 650px;
    height: auto;
    margin: 0 auto 0;
    padding: 0 !important;
    z-index: 888;

    h3 {
        font-weight: 600;
        font-style: italic;
        font-size: 26px;
    }

    input[type="text"] {
        border-radius: 4px;
        background: #f5f5f5;
        border: transparent;
        margin-top: 0;
        padding: 2px 10px;
    }

    .input-group-text {
        position: relative;
        z-index: 999;
        border: none !important;
        background-color: #f5f5f5;
        cursor: pointer;
    }

    .form-control:focus {
        color: #495057;
        background-color: #f5f5f5;
        border-color: none;
        outline: 0;
        box-shadow: none;
    }

    .bg-main-color {
        background-color: var(--main-color);
        color: #fff;
    }
}

// @media (max-width: 767px) {

//   .home-component-search-form {
//     width: 90%;
//     height: auto;
//     margin: -50px auto 0;
//     .col-12, .col-sm-3 {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       margin-top: 20px;
//     }
//     h3 {
//       font-size: 18px;
//     }
//   }

// }
