
.messenger {
  width: 100%;
  min-height: 100vh;
  height: auto;
  background: #fafafa;
  .header {
    position: fixed;
    background: #fff;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #ccc;
    z-index: 1040;
    .col-sm-3 {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #414141;
      padding: 0 15px;
      border-right: 1px solid #ccc;
      .logo {
        //position: absolute;
        left: 15px;
        height: 20px;
        width: auto;
      }
      .cogs {
        position: absolute;
        right: 15px;
        font-size: 20px;
      }
    }
    .col-sm-9 {
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .username {
        font-weight: 500;
      }
      .time {
        font-size: 12px;
        color: #aaa;
      }
      .close {
        position: absolute;
        right: 15px;
        font-size: 20px;
      }
    }
  }
  .body {
    .col-sm-3 {
      padding: 10px 0;
      .contacts {
        position: fixed;
        top: 60px;
        width: 25%;
        height: calc(100vh - 60px);
        overflow-y: auto;
        background: #fff;
        border-right: 1px solid #ccc;
        .input-group {
          padding: 20px 12px 15px 20px;
          .input-group-text {
            position: relative;
            margin-left: -32px;
            background: transparent;
            border: none;
            z-index: 999;
            color: #bbb;
          }
          .form-control {
            box-shadow: none;
            padding-right: 25px;
            border-radius: 20px;
          }
        }
        .chat-selected {
          background-color: rgb(224, 122, 149);
          .username{
            color: rgb(255, 255, 255);
          }
          .date{
            color: rgb(255, 255, 255);
          }
        }
        ul {
          margin: 0 0 15px;
          li {
            a {
              display: block;
              padding: 5px 15px;
              width: 100%;
              height: 60px;
              margin-bottom: 10px;
              color: #414141;
              &:hover{
                background: #f1f1f1;
                color: #414141 $im;
              }
              .avatar {
                position: relative;
                z-index: 1;
                float: left;
                margin-right: 10px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                border: 2px solid #ccc;
                padding: 1px;
              }
              .status {
                position: relative;
                z-index: 2;
                left: -21px;
                top: -4px;
                font-size: 16px;
                &.online {
                  color: #00ee00;
                }
                &.offline {
                  color: #f30;
                }
              }
              .msg {
                font-size: 12px;
                color: #aaa;
                .unread {
                  background-color: rgb(128, 128, 128);
                  padding: 0px 5px;
                  border-radius: 50%;
                  color: rgb(255, 255, 255);
                }
              }
              .username {
                margin-top: 5px;
              }
              .date {
                float: right;
                font-size: 12px;
                color: #ccc;
              }
            }
          }
        }
      }
    }
    .col-sm-9 {
      padding: 0;
      height: auto;
      .chat {
        position: relative;
        top: 60px;
        width: 85%;
        min-height: calc(100vh - 105px);
        height: auto;
        margin: 0 auto;
        padding: 15px 0 80px;
        .chat-date {
          width: 100%;
          height: auto;
          margin: 20px auto;
          text-align: center;
          color: white;
          font-size: 12px;
          text-transform: uppercase;
          background-color: rgba(0,0,0,0.3);
        }
        .chat-conversation {
          width: 100%;
          height: auto;
          .messages {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
          }
          .message {
            border-radius: 4px;
            padding: 12px;
            margin-top: 5px;
            margin-bottom: 5px;
            display: inline-block;
            font-size: 15px;
            .order-title {
              min-width:200px;
              text-align: center;
              background-color: rgba(0,0,0,0.5);
              color: white;
              font-size: 0.8em;
              margin-bottom: 12px;
            }
          }
          .yours {
            align-items: flex-start;
            .message {
              text-align: left;
              margin-right: 20%;
              background-color: #EEE;
              position: relative;
              .hour {
                font-size: 11px;
                color: #aaa;
              }
              .order {
                float: left;
                margin-right: 20px;
                width: 40px;
              }
            }
            .message.last:before {
              content: "";
              position: absolute;
              z-index: 0;
              bottom: 0;
              left: -7px;
              height: 20px;
              width: 20px;
              background: #EEE;
              border-bottom-right-radius: 15px;
            }
            .message.last:after {
              content: "";
              position: absolute;
              z-index: 1;
              bottom: 0;
              left: -10px;
              width: 10px;
              height: 20px;
              background: #fafafa;
              border-bottom-right-radius: 10px;
            }
          }
          .mine {
            align-items: flex-end;
            .message {
              text-align: right;
              color: #fff;
              margin-left: 20%;
              background: $primary-color;
              position: relative;
              .hour {
                font-size: 11px;
                color: #f1f1f1;
                text-align: right;
              }
              .order {
                float: right;
                margin-left: 20px;
                width: 40px;
              }
            }
            .message.last:before {
              content: "";
              position: absolute;
              z-index: 0;
              bottom: 0;
              right: -8px;
              height: 20px;
              width: 20px;
              background: $primary-color;
              border-bottom-left-radius: 15px;
            }
            .message.last:after {
              content: "";
              position: absolute;
              z-index: 0;
              bottom: 0;
              right: -10px;
              width: 10px;
              height: 20px;
              background: #fafafa;
              border-bottom-left-radius: 10px;
            }
          }
        }
      }
      .sender {
        position: fixed;
        width: 75%;
        height: 45px;
        bottom: 0;
        border-top: 1px solid #ccc;
        padding: 7px 10px 0;
        background: #fff;
        z-index: 1040;
        .input-group {
          width: 100%;
          padding: 0;
          .form-control {
            box-shadow: none;
            border: none;
          }
          .input-group-append {
            margin: 7px 5px 0 10px;
            background: transparent;
            border: none;
            z-index: 999;
            color: #bbb;
            font-size: 1.1em;
          }
          .input-group-preppend {
            margin: 0 10px 0 -5px;
            background: transparent;
            border: none;
            z-index: 999;
            color: #bbb;
            font-size: 1.1em;
          }
        }
      }
    }
  }
  .card {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    .card-content {
      height: auto;
      min-height: 100%;
      border-radius: 0;
      border: none;
      .card-header {
        position: fixed;
        top: 0;
        z-index: 1050;
        width: 100%;
        padding: 0;
        background: $primary-color;
        .card-title {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;
          padding: 10px;
          margin: 0;
          .avatar {
            float: left;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 5px;
            border: 2px solid #fff;
            padding: 1px;
          }
          .username {
            font-size: 1.1em;
            font-weight: 500;
            color: #fff;
          }
          .status {
            position: relative;
            z-index: 2;
            left: -20px;
            bottom: -17px;
            font-size: 10px;
            &.online {
              color: #00ee00;
            }
            &.offline {
              color: #f30;
            }
          }
          .close {
            position: absolute;
            top: 50%;
            right: 15px;
            margin-top: -0.5em;
            font-size: 1em;
            color: #fff;
            opacity: 1;
          }
        }
      }
      .card-body {
        background: #fafafa;
        .chat {
          position: relative;
          top: 50px;
          width: 100%;
          min-height: 100vh;
          height: auto;
          margin: 0 0 80px;
          .chat-date {
            width: 100%;
            height: auto;
            margin: 15px auto;
            text-align: center;
            color: #bbb;
            font-size: 12px;
            text-transform: uppercase;
          }
          .chat-conversation {
            width: 100%;
            height: auto;
            .messages {
              margin-top: 0;
              display: flex;
              flex-direction: column;
            }
            .message {
              border-radius: 4px;
              padding: 12px;
              margin-top: 5px;
              margin-bottom: 5px;
              display: inline-block;
              font-size: 15px;
            }
            .yours {
              align-items: flex-start;
              .message {
                margin-right: 15%;
                background-color: #EEE;
                position: relative;
                .hour {
                  font-size: 11px;
                  color: #aaa;
                }
              }
              .message.last:before {
                content: "";
                position: absolute;
                z-index: 0;
                bottom: 0;
                left: -7px;
                height: 20px;
                width: 20px;
                background: #EEE;
                border-bottom-right-radius: 15px;
              }
              .message.last:after {
                content: "";
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: -10px;
                width: 10px;
                height: 20px;
                background: #fafafa;
                border-bottom-right-radius: 10px;
              }
            }
            .mine {
              align-items: flex-end;
              .message {
                color: #fff;
                margin-left: 15%;
                background: $primary-color;
                position: relative;
                .hour {
                  font-size: 11px;
                  color: #f1f1f1;
                  text-align: right;
                }
              }
              .message.last:before {
                content: "";
                position: absolute;
                z-index: 0;
                bottom: 0;
                right: -8px;
                height: 20px;
                width: 20px;
                background: $primary-color;
                border-bottom-left-radius: 15px;
              }
              .message.last:after {
                content: "";
                position: absolute;
                z-index: 0;
                bottom: 0;
                right: -10px;
                width: 10px;
                height: 20px;
                background: #fafafa;
                border-bottom-left-radius: 10px;
              }
            }
          }
        }
      }
      .card-footer {
        position: fixed;
        bottom: -1px;
        width: 100%;
        z-index: 1050;
        display: flex;
        align-items: center;
        background: #fff;
        .input-group {
          width: 100%;
          padding: 0;
          .form-control {
            box-shadow: none;
            border: 1px solid #ccc;
            border-radius: 30px;
          }
          .input-group-append {
            margin: 7px 0 0 10px;
            background: transparent;
            border: none;
            z-index: 999;
            color: #bbb;
            font-size: 1.1em;
          }
          .input-group-preppend {
            margin: 0 12px 0 -10px;
            background: transparent;
            border: none;
            z-index: 999;
            color: #bbb;
            font-size: 1.1em;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 767px) and (orientation: portrait) {

  .messenger {
    .header {
      .col-sm-9 {
        display: none;
      }
    }
    .col-sm-3 {
    .body {
        .contacts {
          width: 100%;
        }
      }
    }
    .card {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      .card-content {
        height: auto;
        min-height: 100%;
        border-radius: 0;
        border: none;
        .card-header {
          position: fixed;
          top: 0;
          z-index: 1050;
          width: 100%;
          padding: 0;
          background: $primary-color;
          .card-title {
            display: flex;
            align-items: center;
            width: 100%;
            height: auto;
            padding: 10px;
            margin: 0;
            .avatar {
              float: left;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              margin-right: 5px;
              border: 2px solid #fff;
              padding: 1px;
            }
            .username {
              font-size: 1.1em;
              font-weight: 500;
              color: #fff;
            }
            .status {
              position: relative;
              z-index: 2;
              left: -20px;
              bottom: -17px;
              font-size: 10px;
              &.online {
                color: #00ee00;
              }
              &.offline {
                color: #f30;
              }
            }
            .close {
              position: absolute;
              top: 50%;
              right: 15px;
              margin-top: -0.5em;
              font-size: 1em;
              color: #fff;
              opacity: 1;
            }
          }
        }
        .card-body {
          background: #fafafa;
          .chat {
            position: relative;
            top: 50px;
            width: 100%;
            min-height: 100vh;
            height: auto;
            margin: 0 0 80px;
            .chat-date {
              width: 100%;
              height: auto;
              margin: 15px auto;
              text-align: center;
              color: #bbb;
              font-size: 12px;
              text-transform: uppercase;
            }
            .chat-conversation {
              width: 100%;
              height: auto;
              .messages {
                margin-top: 0;
                display: flex;
                flex-direction: column;
              }
              .message {
                border-radius: 4px;
                padding: 12px;
                margin-top: 5px;
                margin-bottom: 5px;
                display: inline-block;
                font-size: 15px;
              }
              .yours {
                align-items: flex-start;
                .message {
                  margin-right: 15%;
                  background-color: #EEE;
                  position: relative;
                  .hour {
                    font-size: 11px;
                    color: #aaa;
                  }
                }
                .message.last:before {
                  content: "";
                  position: absolute;
                  z-index: 0;
                  bottom: 0;
                  left: -7px;
                  height: 20px;
                  width: 20px;
                  background: #EEE;
                  border-bottom-right-radius: 15px;
                }
                .message.last:after {
                  content: "";
                  position: absolute;
                  z-index: 1;
                  bottom: 0;
                  left: -10px;
                  width: 10px;
                  height: 20px;
                  background: #fafafa;
                  border-bottom-right-radius: 10px;
                }
              }
              .mine {
                align-items: flex-end;
                .message {
                  color: #fff;
                  margin-left: 15%;
                  background: $primary-color;
                  position: relative;
                  .hour {
                    font-size: 11px;
                    color: #f1f1f1;
                    text-align: right;
                  }
                }
                .message.last:before {
                  content: "";
                  position: absolute;
                  z-index: 0;
                  bottom: 0;
                  right: -8px;
                  height: 20px;
                  width: 20px;
                  background: $primary-color;
                  border-bottom-left-radius: 15px;
                }
                .message.last:after {
                  content: "";
                  position: absolute;
                  z-index: 0;
                  bottom: 0;
                  right: -10px;
                  width: 10px;
                  height: 20px;
                  background: #fafafa;
                  border-bottom-left-radius: 10px;
                }
              }
            }
          }
        }
        .card-footer {
          position: fixed;
          bottom: -1px;
          width: 100%;
          z-index: 1050;
          display: flex;
          align-items: center;
          background: #fff;
          .input-group {
            width: 100%;
            padding: 0;
            .form-control {
              box-shadow: none;
              border: 1px solid #ccc;
              border-radius: 30px;
            }
            .input-group-append {
              margin: 7px 0 0 10px;
              background: transparent;
              border: none;
              z-index: 999;
              color: #bbb;
              font-size: 1.1em;
            }
            .input-group-preppend {
              margin: 0 12px 0 -10px;
              background: transparent;
              border: none;
              z-index: 999;
              color: #bbb;
              font-size: 1.1em;
            }
          }
        }
      }
    }
  }

}
