.story {
    display: inline-block;
    width: 140px;
    height: 140px;
    margin: 10px 0 30px;
    border-radius: 50%;
    font-weight: 500;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.story-header {
    width: 100%;
    height: 40px;
    margin: 10px 10px 0;
    text-align: center;
    .avatar {
        float: left;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, 0.8);
    }
}

.story-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;
    height: 100%;
    padding: 0;
    margin-top: 40px;
}

.story a {
    cursor: pointer;
    font-size: 20px;
    color: $primary-color;
    margin-bottom: 10px;
    font-size: 1em;
    &:hover {
        color: $primary-color-hover;
    }
}

.carousel-indicators {
    bottom: 57px;
}

// .customNavigation {
//     a {
//         display: block;
//         width: 30px;
//         height: 30px;
//         border-radius: 50%;
//         background: rgba(255, 255, 255, 0.8);
//         color: $primary-color $im;
//         text-align: center;
//         line-height: 30px;
//         &:hover {
//             background: #fff;
//             color: $primary-color-hover;
//         }
//     }
// }
// .prev {
//     position: absolute;
//     width: 100%;
//     top: 50%;
//     left: 7px;
//     margin-top: -15px;
//     z-index: 10;
// }
// .next {
//     position: absolute;
//     width: 100%;
//     top: 50%;
//     right: 7px;
//     margin-top: -15px;
//     z-index: 10;
// }
@media (max-width: 767px) {
    .story {
        width: 65px;
        height: 65px;
        margin: 10px 0 0;
    }
    .story-body {
        display: none;
    }
}

// carousel default
.carousel-stage {
    position: relative;
    z-index: 1;
    min-height: 300px;
    margin: 0 -6px;
    .progress {
        height: 8px;
        border-radius: 30px;
        .progress-bar {
            background: var(--main-color);
        }
    }
    .courses-image-item {
        border-radius: 5%;
        object-fit: cover;
    }
    .courses-item {
        padding: 15px;
    }
    .courses-title {
        text-align: center;
        a {
            color: black !important;
            font-weight: 400 !important;
        }
    }
}

.customNavigation {
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.2);
        color: var(--main-color) $im;
        text-align: center;
        &:hover {
            background: rgba(255, 255, 255, 0.6);
            color: var(--main-color);
        }
    }
}

.prev {
    position: absolute;
    width: 100%;
    top: 50%;
    left: -15px;
    margin-top: -15px;
    z-index: 10;
}

.next {
    position: absolute;
    width: 100%;
    top: 50%;
    right: -15px;
    margin-top: -15px;
    z-index: 10;
}

.owl-dots {
    position: absolute;
    top: -40px;
    right: 0;
    outline: none $im;
    .owl-dot {
        background: #ccc $im;
        border-radius: 50%;
        height: 10px;
        width: 10px;
        padding: 0 $im;
        border: none;
        margin: 0 2px;
        outline: none $im;
        &.active {
            background: var(--main-color) $im;
            outline: none $im;
        }
    }
}

.carousel-stories {
    display: inline-block;
    width: 145px;
    height: 145px;
    margin: 20px 0;
    border-radius: 6px;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: 500;
    background-position: center !important;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    background-position: center !important;
    .item {
        &:focus {
            outline: none;
        }
    }
    .stories-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        text-align: center;
        height: 100%;
        padding: 0;
        a {
            font-size: 12px;
            color: #fff;
            margin-bottom: 10px;
            text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
            &:hover {
                color: #ccc;
            }
        }
    }
}

.carousel-training {
    margin: 40px 0 0;
    
    .cover {
        width: 100%;
        height: 300px;
        border-radius: 0 !important;
        object-fit: cover;
    }
}

@media (max-width: 767px) {
    .customNavigation {
        a {
            width: 20px;
            height: 20px;
        }
    }
    .prev {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        margin-top: -30px;
        z-index: 10;
    }
    .next {
        position: absolute;
        width: 100%;
        top: 50%;
        right: -1px;
        margin-top: -30px;
        z-index: 10;
    }
}