/* You can add global styles to this file, and also import other style files */
html {
  &:has(.blured) {
    overflow: hidden !important;
  }

  height: 100%;
  width: 100%;

  /* overflow: hidden; */
}

body {
  height: 100%;
  width: 100%;

  /* overflow: hidden; */
}
.mat-progress-bar-fill::after {
  background-color: var(--primary-color);
}

.mat-progress-bar-buffer {
  background-color: #eec4cf;
}

.mat-dialog-override {
  height: 0px;
  mat-dialog-container {
    > :first-child {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    mat-dialog-content,
    div[mat-dialog-content] {
      flex-grow: 1;
      max-height: unset;
    }
  }
}
html:has(.blured) {
  overflow: hidden !important;
}

html,
body,
.asap-main-loader {
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
}

.asap-main-loader {
  color: silver;
}

.b2c-loader {
  margin: 0 auto;
  width: 60px;
  height: 60px;
  /* position: absolute; */
  display: block;
  /* left: 0; */
  /* right: 0; */
  z-index: 1;
  text-align: center;
  top: 50%;
}

.b2c-loader:before {
  content: "";
  display: block;
  /* padding-top: 100%; */
}

.b2c-circular {
  animation: b2c-rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  /* position: absolute; */
  /* top: 0; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0; */
  margin: auto;
}

.b2c-path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: b2c-dash 1.5s ease-in-out infinite, b2c-color 2s ease-in-out infinite;
  stroke-linecap: round;
}

.b2c-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  z-index: 9999;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden !important;
}

.b2c-bg .b2c-text {
  margin-top: -25px !important;
  color: black;
  font-weight: 100;
}

@keyframes b2c-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes b2c-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes b2c-color {
  100%,
  0% {
    stroke: silver;
  }

  50% {
    stroke: gray;
  }

  100% {
    stroke: silver;
  }
}

.blured {
  filter: blur(10px);
}

.asap-main-loader {
  height: 100%;
  width: 100%;

  /* overflow: hidden; */

  color: silver;
}

.b2c-loader {
  margin: 0 auto;
  width: 60px;
  height: 60px;

  /* position: absolute; */
  display: block;

  /* left: 0; */
  /* right: 0; */
  z-index: 1;
  text-align: center;
  top: 50%;

  &:before {
    content: "";
    display: block;

    /* padding-top: 100%; */
  }
}

.b2c-circular {
  animation: b2c-rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;

  /* position: absolute; */
  /* top: 0; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0; */
  margin: auto;
}

.b2c-path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: b2c-dash 1.5s ease-in-out infinite, b2c-color 2s ease-in-out infinite;
  stroke-linecap: round;
}

.b2c-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  z-index: 9999;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden !important;

  .text {
    margin-top: -25px !important;
    color: gray;
  }
}

@keyframes b2c-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes b2c-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes b2c-color {
  100%,
  0% {
    stroke: silver;
  }

  50% {
    stroke: gray;
  }

  100% {
    stroke: silver;
  }
}

.blured {
  filter: blur(10px);
}
