.grid-card-shadow {

  .mat-card:not([class*=mat-elevation-z]) {
    box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
    -moz-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
    -webkit-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
  }

  .card {
    box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
    -moz-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
    -webkit-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
  }

  .generic-card-price {
    box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
    -moz-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
    -webkit-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
  }

  .card-comments {
    input[type="text"] {

      box-shadow: 1px 1px 2px #c9bfbb !important;
      -moz-box-shadow: 1px 1px 2px #c9bfbb !important;
      -webkit-box-shadow: 1px 1px 2px #c9bfbb !important;
    }
  }

  .card-file {
    box-shadow: 0 0 30px rgba(204, 204, 204, 0.4) !important;
    --moz-box-shadow: 0 0 30px rgba(204, 204, 204, 0.4) !important;
    --webkit-box-shadow: 0 0 30px rgba(204, 204, 204, 0.4) !important;
  }

  .home-component-search-form input[type=text] {
    box-shadow: 1px 1px 2px #c9bfbb !important;
    --moz-box-shadow: 1px 1px 2px #c9bfbb !important;
    --webkit-box-shadow: 1px 1px 2px #c9bfbb !important;
  }

  .cover-profile {

    .event-img-profile {
      box-shadow: 0px 0px 5px 0px rgba(170, 170, 170, 0.54) !important;
      --moz-box-shadow: 0px 0px 5px 0px rgba(170, 170, 170, 0.54) !important;
      --webkit-box-shadow: 0px 0px 5px 0px rgba(170, 170, 170, 0.54) !important;
    }
  }

  .gallery-profile {

    .gallery-profile-photo {
      box-shadow: 0px 0px 5px 0px rgba(170, 170, 170, 0.54) !important;
      --moz-box-shadow: 0px 0px 5px 0px rgba(170, 170, 170, 0.54) !important;
      --webkit-box-shadow: 0px 0px 5px 0px rgba(170, 170, 170, 0.54) !important;
    }
  }

  .card-live-cover {
    box-shadow: 0 0px 1px black !important;
    --moz-box-shadow: 0 0px 1px black !important;
    --webkit-box-shadow: 0 0px 1px black !important;
  }

  .card-school {
    box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
    -moz-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
    -webkit-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
  }

  .card-exhibitors {
    box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
    -moz-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
    -webkit-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
  }

  .card-list {
    box-shadow: 1px 1px 10px rgba(201,191,187,0.7) !important;
    -moz-box-shadow: 1px 1px 10px rgba(201,191,187,0.7)  !important;
    -webkit-box-shadow: 1px 1px 10px rgba(201,191,187,0.7)  !important;
  }

  .form-feed {
    box-shadow: 1px 1px 15px rgb(201 191 187 / 70%) !important;
    -moz-box-shadow: 1px 1px 15px rgba(201, 191, 187, .7) !important;
    -webkit-box-shadow: 1px 1px 15px rgb(201 191 187 / 70%) !important;
  }

  .feed-card {
    box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
    -moz-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
    -webkit-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
  }

  .btn {
    box-shadow: 0 2px 2px 0 rgba(153, 153, 153, .14), 0 3px 1px -2px rgba(153, 153, 153, .2), 0 1px 5px 0 rgba(153, 153, 153, .12) !important;
    --moz-box-shadow: 0 2px 2px 0 rgba(153, 153, 153, .14), 0 3px 1px -2px rgba(153, 153, 153, .2), 0 1px 5px 0 rgba(153, 153, 153, .12) !important;
    --webkit-box-shadow: 0 2px 2px 0 rgba(153, 153, 153, .14), 0 3px 1px -2px rgba(153, 153, 153, .2), 0 1px 5px 0 rgba(153, 153, 153, .12) !important;
  }

  .owl-journey {
    
    ::ng-deep .owl-item.active.center {
  
      .item {
       
        box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
        -moz-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
        -webkit-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
      }
    }
  
    ::ng-deep .owl-item {
      
      .item { 
        box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
        -moz-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
        -webkit-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) !important;
      }
    }
  }
}



.jogar-fora {
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}