.navbar-custom {
    z-index: 999;
    background: $bg-navbar;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    .toggle {
        margin-right: 15px;
        font-size: 18px;
        i {
            color: $link-navbar;
        }
    }
    .navbar-brand .logo {
        width: auto;
        height: 25px;
        margin-top: 3px;
    }
    .navbar-toggler {
        padding: 0 $im;
        font-size: 1.5rem;
        background-color: transparent;
        border: 1px solid transparent;
    }

    .login {
        .nav-link {
            i {
                color: $link-navbar;
            }
        }
    }

    .navbar-icon {
        margin-top: 12px;
        color: $primary-color $im;
    }
    .badge {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 15px;
        min-height: 15px;
        width: auto;
        height: auto;
        font-size: 10px;
        font-family: sans-serif;
        background: $secundary-color;
        color: #fff;
        position: absolute !important;
        left: inherit  !important;
        right: 0  !important;
        top: 7px  !important;
    }
    .nav-link {
        color: $link-navbar;
        font-weight: 500;
        margin-left: 10px;
        text-transform: uppercase;
        font-size: 14px;
        position:relative;
        &:hover {
            opacity: 0.8;
        }
        p {
            font-weight:600;
            margin-top: 3px;
        }
        .avatar {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            position: relative;
            top: 3px;
            border: 2px solid #e8e8e8;
        }
        .icon {
            position: relative;
            width: 20px;
            height: 20px;
            margin-top: 7px;
            font-size: 1.4em;
        }

        .btn-add {
            cursor: pointer;
            border-radius: 4px;
            margin-top: -4px;
            margin-left: 10px;
            padding: 0.5rem 0.8rem;
            background: #fff;
            text-transform: uppercase;
            font-size: 14px;
            .fa {
                font-size: 13px;
                margin-right: 5px;
            }
        }
    }
    .navbar-nav {
        display: inline-flex;
        align-items: center;

        .dropdown-toggle::after {
            display: none;
        }
        .dropdown-menu::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
        }
        .dropdown-menu::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
        }
        .dropdown-menu::-webkit-scrollbar-thumb {
            background-color: $primary-color;
        }
        .dropdown-menu {
            min-width: 300px $im;
            -webkit-box-shadow: 1px 7px 26px -6px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 1px 7px 26px -6px rgba(0, 0, 0, 0.75);
            box-shadow: 1px 7px 26px -6px rgba(0, 0, 0, 0.75);
            min-height: 50px;
            max-height: 415px;
            overflow-y: auto !important;
            padding: 0;
            margin-top: 8px;
            border-color: #eee;
            font-size: 15px;
            -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            .msg {
                padding: 0;
                overflow: auto;
                a {
                    color: #3c4858 !important;
                    font-size: 16px;
                }
            }
            .dropdown-item {
                padding: 15px;
                .fa {
                    margin-right: 10px;
                }
                &:hover {
                    background: #f9f9f9;
                    color: #414141;
                }
            }
            .dropdown-menu-header {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                min-height: 20px;
                padding: 15px;
                background: $link-navbar !important;
                color: $bg-navbar !important;
                font-size: 13px;
                .avatar {
                    float: left;
                    width: 40px;
                    height: 40px;
                    margin-right: 15px !important;
                    border-radius: 50%;
                    border: 2px solid #FFF;
                }
                .user {
                    margin-bottom: 5px;
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 1;
                }
            }
            .dropdown-menu-notifications {
                min-width: 300px;
                .dropdown-item.active {
                    background: #f5f5f5;
                }
                .dropdown-item {
                    min-height: 72px;
                    padding: 8px 6px !important;
                    border-bottom: 1px solid #eee;
                    overflow: hidden;
                    color: $secundary-color;
                    .avatar {
                        float: left;
                        width: 45px;
                        height: 45px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                    .msg {
                        font-size: 12px;
                        word-break: break-word;
                        white-space: break-spaces;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline-block;
                        width: 80%;
                        padding: 0;
                    }
                    .username {
                        font-weight: 700;
                        margin-top: 0;
                    }
                    .date {
                        float: right;
                        font-size: 11px;
                        color:#b1b1b1;
                        line-height: 7px;
                    }
                    .status {
                        position: absolute;
                        left: 41px;
                        display: none;
                        .fa {
                            color: #e0e0e0;
                        }
                        &.online{
                            .fa {
                                color: #20de25;
                                display: block;
                            }
                        }
                    }

                }
            }
        }
    }
    .dropdown-menu-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        border-top: 1px solid #f4f4f4;
        font-size: 13px;
        background-color: #e9ecef;

        a {
            color: #16181b !important;
        }
    }
}

@media (max-width: 767px) {
    .navbar-celular {
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
        height: 56px;
        .row {
            display: flex;
            align-items: center;
            height: 56px;
            padding: 0.5rem 1rem;
            background: $bg-navbar;
        }
        .toggle {
            margin-right: 0;
            font-size: 1.2rem;
            i {
                color: $link-navbar;
            }
        }
        .logo {
            width: auto;
            height: 20px;
            margin-top: 7px;
        }
        .login {
            font-size: 1.2rem;
            color: $link-navbar;
        }
        .dropdown-toggle::after {
            display: none;
        }
        .dropdown {
            .user {
                width: 1.5rem;
                height: 1.5rem;
                margin-top: 7px;
                border-radius: 50%;
            }
            .dropdown-menu {
                min-width: 250px;
                padding: 0 !important;
                border-radius: 4px;
            }
            .dropdown-menu-header {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                min-height: 20px;
                padding: 15px;
                background: $link-navbar !important;
                color: $bg-navbar !important;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                .name {
                    margin-bottom: 5px;
                    font-size: 13px;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 1;
                }
                .email {
                    font-size: 12px;
                }
            }
            .dropdown-item {
                display: block;
                width: 100%;
                padding: .5rem 1rem;
                clear: both;
                font-weight: 400;
                color: #212529;
                text-align: inherit;
                white-space: nowrap;
                background-color: transparent;
                border: 0;
                font-size: 12px;
                i {
                    margin-right: 10px;
                }
            }
        }
    }
    .navbar-mobile {
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 55px;
        z-index: 1040;
        background: $link-navbar;
        .toggle {
            margin-right: 0;
        }
        .dropdown-toggle:after {
            content: none;
        }
        ul {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            height: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
            background-color: $primary-color;
            li {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: $bg-navbar;
                margin: 5px 0 0;
                padding: 0;
                a {
                    display: block;
                    cursor: pointer;
                    span {
                        display: none;
                    }
                    i {
                        font-size: 1.3em;
                        color: white;
                        margin-right: 0;
                    }
                }
            }

            .others-menu {
                color: black;
                padding: 0;

                .nav-item {
                    margin-top: 0;
                }

                i {
                    color: black;
                }

                span {
                    display: inline-block;
                }

                li {
                    display: block;
                }
            }
        }
    }
}


/* ----------------------------------------------
 * Generated by Animista on 2020-7-7 1:51:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */


/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */

@-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

.navbar-transparent {
    background: transparent;
    background-image: linear-gradient(-180deg, rgba(0, 0, 0, .64), transparent);
    box-shadow: none;
}

.wrapper-navbar-transparent {
    top: 0 !important;
}

.navbar-search {
    .navbar-search-input {
        border: 0;
        border-bottom: 1px solid #AAA;
        background: none;
        color: var(--link-navbar);
    }
}
.navbar-custom .navbar-nav .dropdown-menu .msg {
  padding:0px;
}

.w-0 {
    width: 0;
    overflow: hidden;
}


@media (max-width: 767px) {
    .navbar-nav .nav-link .badge {
        right: -10px !important;
        top: -1px !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 15px;
        min-height: 15px;
        width: auto;
        height: auto;
        font-size: 10px;
        font-family: sans-serif;
        background: $secundary-color;
        color: #fff;
        position: absolute !important;
        left: inherit  !important;
    }
    .dropdown-menu-notifications {
        min-width: 300px;
        .dropdown-item.active {
            background: #f5f5f5;
        }
        .dropdown-item {
            min-height: 72px;
            padding: 8px 6px !important;
            border-bottom: 1px solid #eee;
            overflow: hidden;
            color: $secundary-color;
            .avatar {
                float: left;
                width: 45px;
                height: 45px;
                border-radius: 50%;
                margin-right: 10px;
            }
            .msg {
                font-size: 12px;
                word-break: break-word;
                white-space: break-spaces;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                width: 80%;
                padding: 0;
            }
            .username {
                font-weight: 700;
                margin-top: 0;
            }
            .date {
                float: right;
                font-size: 11px;
                color:#b1b1b1;
                line-height: 7px;
            }
            .status {
                position: absolute;
                left: 41px;
                display: none;
                .fa {
                    color: #e0e0e0;
                }
                &.online{
                    .fa {
                        color: #20de25;
                        display: block;
                    }
                }
            }

        }
}
}
/* width */
::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #CCC;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
