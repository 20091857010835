.channels-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .bubble {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
  }
}

.channels-title.clickable {
  h4:hover {
    color: var(--main-color);
    transition: 0.3s;
  }
}

.channel {
  // margin-top: 56px;

  .cover-banner {
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: default;

    .banner-channel {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }

    .overlay-banner {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
      top: 0;
      left: 0;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  .channel-header {
    width: 100%;
    height: 150px;
    background: #fafafa;
    border-bottom: 1px solid #eee;

    .profile {
      width: 1000px;
      margin: 0 auto;
      padding-top: 15px;

      .avatar {
        float: left;
        width: 80px;
        height: 80px;
        margin: 0 15px 0 0;
        border-radius: 50%;
        background: #000;
        border: 3px solid #fff;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      h3 {
        font-size: 30px;
        font-weight: 400;
        text-transform: uppercase;
        margin-top: 15px;
      }

      h5 {
        font-size: 16px;
      }

      .menu-subscriber {
        float: right;
        margin-top: 20px;
        a {
          font-size: 18px;
          margin-left: 10px;
          color: #a1a1a1;
          background: #eee;
          padding: 10px;
          border-radius: 4px;
          font-size: 14px;
          &:hover {
            background-color: var(--main-color);
            color: #fff;
          }
        }
        .btn-subscriber-grey {
          background: #e9e9e9;
          color: #3c4858;
        }
      }
    }
  }

  // MAT-TAB
  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: var(--main-color) $im;
    border: 0 $im;
  }

  .mat-tab-header {
    border-bottom: none $im;
  }

  .mat-tab-body-content {
    padding: 20px 5px;
    overflow: hidden $im;
  }

  .mat-tab-list {
    display: inline-flex $im;
    justify-content: flex-start $im;
    align-items: center $im;
  }

  .mat-tab-label,
  .mat-tab-link {
    text-transform: uppercase;
  }
}

.upload-image-banner {
  height: 187px;
  object-fit: cover;
  object-position: center;
}

.video-upload-overlay {
  position: absolute;
  top: 69px;
  color: #fff;
  text-align: center;
  font-size: 20px;
  background-color: rgba(221, 221, 221, 0.3);
  width: 95%;
  padding: 10px 18px;
  z-index: 1050;
}

.empty-list {
  text-align: center;
  padding: 10px;
  background: #ececec;
}

.avatar-channel-files {
  float: left;
  width: 40px;
  height: 40px;
  margin: 0 15px 0 0;
  border-radius: 50%;
  background: #000;
  border: 3px solid #fff;
}

/* WATCH */

.watch {
  iframe {
    width: 100%;
    height: 403px;
  }
  a,
  h4 {
    font-size: 20px;
    font-weight: 500;
    color: #3c4858;
  }
  :hover {
    color: var(--main-color);
    transition: 0.3s;
  }
  > ul {
    margin: 5px 0 20px;
    padding: 0 0 30px 0;
    border-bottom: 1px solid #e5e5e5;
    > {
      li {
        float: right;
        margin-left: 40px;
        > a {
          color: #b1b1b1 $im;
          font-size: 15px;
          &:hover {
            color: var(--main-color);
          }
          > i {
            margin-right: 5px;
          }
        }
      }
      .views {
        float: left;
        margin-left: 0;
        font-size: 16px;
        color: #b1b1b1;
      }
    }
  }
  .video {
    width: 100%;
    height: 100%;
    max-height: 450px;
    background: #000;
  }
}

.channel-info {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 15px;
  .avatar {
    float: left;
    width: 42px;
    height: 42px;
    margin-right: 15px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  h4 {
    font-size: 16px;
    font-weight: 600;
  }
  p {
    color: #b1b1b1;
    font-size: 13px;
  }
  .text {
    margin: 15px 0 15px 60px;
    color: #3c4858;
  }
  .menu-subscriber {
    float: right;
    a {
      font-size: 18px;
      margin-left: 10px;
      color: #a1a1a1;
      &:hover {
        color: var(--main-color);
      }
    }
    .btn-subscriber-grey {
      background: #e9e9e9;
      color: #3c4858;
    }
  }
  .show-more {
    margin: 15px 0 15px 60px;
    a {
      font-size: 14px;
      &:hover {
        color: var(--main-color) $im;
      }
    }
    p {
      font-size: 14px;
      color: #3c4858;
      margin-bottom: 16px;
    }
    .link {
      text-align: left;
      padding-bottom: 20px;
    }
  }
}

/* comments */

.channel-comments {
  h4 {
    font-weight: 500;
    color: #6e4858;
    font-size: 18px;
    margin-top: 10px;
  }
  .avatar {
    float: left;
    width: 40px !important;
    height: 40px !important;
    margin-right: 15px !important;
    border-radius: 50%;
  }
  p {
    margin-left: 60px;
  }
  .menu {
    width: calc(100% - 60px);
    margin: 15px 0 0 60px;
    padding: 0;
    list-style: none;
    li {
      float: left;
      margin-right: 30px;
      font-size: 14px;
      &:last-child {
        float: none;
      }
    }
  }
  .comment {
    width: calc(100% - 60px) !important;
    margin: 0 0 15px 60px;
    padding: 0;
    .avatar {
      float: left;
      width: 30px;
      height: 30px;
      margin-right: 15px;
      border-radius: 50%;
    }
  }
}

.reply .avatar {
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  border-radius: 50%;
}

.reply {
  width: calc(100% - 60px) !important;
  margin: 0 0 30px 60px;
  padding: 0;
}

.li-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.py-4 {
  padding-right: 10px !important;
}

.used {
  color: orange !important;
}

.unused {
  color: red !important;
}

/* playlist */

.playlist {
  .card-body {
    width: 100%;
    height: 100px;
    .thumb-video {
      width: 100%;
      height: 100px;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      object-fit: cover;
      object-position: center;
    }
    .col-7 {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      a {
        color: #3c4858;
        &:hover {
          color: var(--main-color);
        }
      }
      h5 {
        font-size: 15px;
        margin: 0;
        padding: 0;
        font-weight: 500;
      }
      p {
        font-size: 13px;
        color: #b1b1b1;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }
    }
  }
}

@media (max-width: 800px) {
  .channel {
    .cover-banner {
      height: 100px;
    }
    .channel-header {
      height: 120px;
      .profile {
        width: 90%;
        margin: 0 5%;
        .avatar {
          float: left;
          width: 50px;
          height: 50px;
        }
        h3 {
          margin-top: 5px;
          font-size: 20px;
        }
        h5 {
          font-size: 14px;
        }
      }
    }
  }

  .watch {
    a,
    h4 {
      font-size: 20px;
      font-weight: 500;
      color: #3c4858 $im;
    }
    > ul {
      margin: 5px 0 20px;
      padding: 0 0 30px 0;
      border-bottom: 1px solid #e5e5e5;
      > {
        li {
          float: right;
          margin-left: 10px;
          > a {
            color: #b1b1b1 $im;
            font-size: 15px;
            &:hover {
              color: var(--main-color);
            }
            > i {
              margin-right: 5px;
            }
          }
        }
        .views {
          float: left;
          margin-left: 0;
          font-size: 16px;
          color: #b1b1b1;
        }
      }
    }
    .video {
      width: 100%;
      height: 200px $im;
      background: #000;
      margin-top: -25px;
    }
  }

  .channel-info {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 15px;
    .avatar {
      float: left;
      width: 42px;
      height: 42px;
      margin-right: 15px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }
    h4 {
      font-size: 16px;
      font-weight: 600;
    }
    p {
      color: #b1b1b1;
      font-size: 13px;
    }
    .text {
      margin: 15px 0 15px 60px;
      color: #3c4858;
    }
    .menu-subscriber {
      display: none;
    }
    .show-more {
      margin: 15px 0;
    }
  }
}
