.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 50px;
    -o-object-fit: cover;
    object-fit: cover;
}

.clickable {
    cursor: pointer !important;
}
