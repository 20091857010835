
.bg-client {
  background: #f27173;
}

.bg-grey {
  background: #f4f4f4;
}

.bg-dark {
  background: #3e484e $im;
}



.banner-school {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 180px;
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
  h2 {
    margin: 0;
  }
  h3 {
    color: #fff;
    font-size: 1.3em;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}

.card-school {
  border-radius: 6px;
  margin-bottom: 30px;
  box-shadow: 1px 1px 10px rgba(201,191,187,0.7);
  -moz-box-shadow: 1px 1px 10px rgba(201,191,187,0.7);
  -webkit-box-shadow: 1px 1px 10px rgba(201,191,187,0.7);
}
  .bg-grey {
    background: #f4f4f4;
  }

  .bg-grey-dark {
    background: #eee;
 

  .row {
    margin-left: 0;
    margin-right: 0;

    .col-sm-3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .content-girl {
        position: relative;
        margin-top: 15px;

        .girl {
          width: 48%;
          height: auto;
        }

        .body-girl {
          stroke-miterlimit:10;
          opacity: 1;
        }

        .activedSVG {
          opacity: 1;
        }

        .body-font {
          font-family:'Roboto-Black';
        }

        .body-size {
          font-size: 8px;
          text-align: center;
        }

        .percentual-text {
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          color: #fff;
        }
      }

      .text-level {
        margin-bottom: 15px;
        margin-top: 5px;
        font-weight: 600;
        text-transform: uppercase;
        color: #414141;
        text-align: center $im;
      }

      .price {
        font-size: 18px;
        font-weight: 600;
        color: #5cb85c;
        margin-bottom: 10px;
      }

      .stamp {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 15px;
        img {
          width: 50%;
          height: auto;
        }
        .text {
          position: absolute;
          z-index: 2;
          transform:rotate(-14deg);
          -webkit-transform:rotate(-14deg);
          -moz-transform:rotate(-14deg);
          -o-transform: rotate(-14deg);
          margin-top: 2px;
          margin-left: 2px;
          font-size: 13px;
          font-weight: 600;
          color: #999;
        }
      }

      .buttons {
        height: auto;
      }

    }

    .col-sm-4 {
      height: 180px;
      margin: 0;
      padding: 0;

      img {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

    }

    .card-main {

      .title {
        max-height: 55px;
        margin-bottom: 15px;
        padding: 15px 0;
        line-height: 1.15;
        font-size: 1.2em;
        font-weight: 600;
        text-transform: uppercase;
        overflow: hidden;
      }

      .description {
        max-height: 85px;
        margin-bottom: 10px;
        font-size: .9em;
        overflow: hidden;
      }

    }

    .col-sm-9 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        max-height: 55px;
        margin-bottom: 15px;
        padding: 15px 0;
        line-height: 1.15;
        font-size: 1.2em;
        font-weight: 600;
        text-transform: uppercase;
        overflow: hidden;
      }

    }


  }

}

.card-levels {

  .col-sm-5, .col-sm-7 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }

  .col-sm-5 {
    h2 {
      font-size: 1.8em;
      font-weight: 600;
      text-transform: uppercase;
    }
    h4 {
      color: $primary-color;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .col-sm-7 {
    font-size: 1.3em;
    color:#444;
    text-align: center;
  }

}

.card-school-details {
  .title {
    max-height: 70px;
    margin-bottom: 15px;
    padding: 15px 0;
    line-height: 1.15;
    font-size: 1.4em;
    font-weight: 600;
    text-transform: uppercase;
    overflow: hidden;
  }

  .cover {
    width: 100%;
    height: 300px;
    object-fit: cover;
    img {
      width: 100%;
      height: 100%;
    }
  }
}


@media (max-width: 767px) {

  .card-school {
    margin: 0 0 15px;

    .col-sm-4 {
      img {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 0 $im;
      }
    }

    .title, .description {
      text-align: center;
    }

    .stamp {
      margin: 20px 0;
      img {
        width: 100px $im;
        height: auto;
      }
    }

    .buttons {
      margin-bottom: 20px;
    }

  }

}
