.btn {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
}

.btn-sm {
  font-size: 12px;
}

.btn-xs { padding: 1px 5px; font-size: 0.5em !important; line-height: 1.5; border-radius: 3px; }

.social-button-radius {
  border-radius: 34px;
  height: 55px;
  font-size: 30px;
  line-height: 37px;
  padding-right: 39px;
  text-align: center;
  width: 55px;
}

// BTN-PRIMARY

.btn-primary {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;

  &:hover {
    color: #fff;
    background-color: $primary-color-hover;
    border-color: $primary-color-hover;
  }

  &:focus,
  &.focus {
    background-color: $primary-color-hover;
    border-color: $primary-color-hover;
    box-shadow: 0 0 0 0.2rem $primary-box-shadow;
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: $primary-color;
    border-color: $primary-color;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #fff;
      background-color: $primary-color-hover;
      border-color: $primary-color-hover;
    }
  }
}

.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: $primary-color-hover;
  border-color: $primary-color-hover;
}

.btn-primary:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 0.2rem $primary-box-shadow;
  }
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem $primary-box-shadow;
}

// BTN-OUTLINE-PRIMARY

.btn-outline-primary {
  color: $primary-color;
  background-color: #fff;
  border-color: #fff;

  &:hover {
    color: $primary-color-hover;
    background-color: #fff;
    border-color: #fff;
  }

  &:focus,
  &.focus {
    background-color: #fff;
    border-color: #fff;
    box-shadow: 0 0 0 0.2rem $primary-outline-box-shadow;
  }

  &.disabled,
  &:disabled {
    color: $primary-color;
    background-color: #fff;
    border-color: #fff;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: $primary-color-hover;
      background-color: #fff;
      border-color: #fff;
    }
  }
}

.show > .btn-outline-primary.dropdown-toggle {
  color: $primary-color-hover;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-primary:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 0.2rem $primary-outline-box-shadow;
  }
}

.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem $primary-outline-box-shadow;
}

.btn-facebook {
  color: #fff;
  background-color: #4267b2;
  border-color: #4267b2;

  &:hover {
    color: #fff;
    background-color: #395896;
    border-color: #395896;
  }

  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 103, 178, 0.5);
  }

  &.disabled,
  &:disabled {
    color: #fff;
    background-color: #4267b2;
    border-color: #4267b2;
  }

  &:not(:disabled):not(.disabled) {
    &:active,
    &.active {
      color: #fff;
      background-color: #395896;
      border-color: #395896;
    }
  }
}

.show > .btn-facebook.dropdown-toggle {
  color: #fff;
  background-color: #395896;
  border-color: #395896;
}

.btn-facebook:not(:disabled):not(.disabled) {
  &:active:focus,
  &.active:focus {
    box-shadow: 0 0 0 0.2rem rgba(66, 103, 178, 0.5);
  }
}

.show > .btn-facebook.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 103, 178, 0.5);
}

// BTN ROUNDED

.btn-rounded {
  border-radius: 30px;
}

// BTN FLOAT

.btn-float {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: $primary-color;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  animation: bot-to-top 2s ease-out;

  i {
    color: #fff;
    font-size: 24px;
  }
}

.menu-float {
  position: fixed;
  right: 20px;
  padding-bottom: 20px;
  bottom: 60px;
  z-index: 100;

  li {
    list-style: none;
    margin-bottom: 10px;

    a {
      background-color: #eee;
      color: $primary-color;
      border-radius: 50%;
      text-align: center;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 24px;
      }
    }
  }

  &:hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
}

a#menu-share {
  + ul {
    visibility: hidden;
  }

  &:hover + ul {
    visibility: visible;
    animation: scale-in 0.5s;
  }

  i {
    animation: rotate-in 0.5s;
  }

  &:hover > i {
    animation: rotate-out 0.5s;
  }
}

// @keyframes bot-to-top {
//   0% {
//     bottom: -40px;
//   }
//
//   50% {
//     bottom: 40px;
//   }
// }

@keyframes scale-in {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes rotate-in {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-out {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.tooltip-custom {
  font-size: 13px;
  background: $primary-color;
}

// Upload - input file
.fileUpload {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 150px;
  background: #e7e7e7;
  border: 2px dashed #fff;

  i {
    font-size: 60px;
    color: #aaa;
  }

  input.upload {
    width: 100%;
    height: 150px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }
}

// mat-radio-button
.categories {
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.category {
  position: relative;
  width: 100%;
  height: 81px;
  margin: 30px 0;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .icon {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 50%;
    background: $primary-color;

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 50px;
      color: #fff;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      background-color: $primary-color;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-left: -10px;
    height: 24px;
    width: 24px;
    background-color: #eee;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 8px;
      left: 8px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
    }
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
}

.btn-menu {
  position: absolute;
  width: auto;
  height: auto;
  top: 10px;
  right: 15px;
}

@media (max-width: 767px) {
  .btn-float {
    bottom: 60px;
    right: 10px;
  }

  .menu-float {
    right: 10px;
    padding-bottom: 20px;
    bottom: 100px;
  }

  .categories {
    display: block;
    text-align: center;
    margin-top: 40px;

    .category {
      position: relative;
      width: 60px;
      height: auto;
      margin: 20px 5px 20px;
      text-align: center;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .title {
        display: none;
      }

      .icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: $primary-color;

        i {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          font-size: 24px;
          color: #fff;
        }
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .checkmark {
          background-color: $primary-color;

          &:after {
            display: block;
          }
        }
      }

      .checkmark {
        position: absolute;
        bottom: -20px;
        left: 50%;
        margin-left: -6px;
        height: 12px;
        width: 12px;
        background-color: #eee;
        border-radius: 50%;

        &:after {
          content: "";
          position: absolute;
          display: none;
          top: 4px;
          left: 4px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: white;
        }
      }

      &:hover input ~ .checkmark {
        background-color: #ccc;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.btn-black {
  background: #000;
  color: #fff;
}

.btn-black:hover {
  color: #eee;
}
