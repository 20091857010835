
.bullet-wrap {
  position: relative;
  .before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    top: 50%;
    height: 2px;
    background: #ddd;
    width: 100%;
    z-index: -1;
  }
  .after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    top: 50%;
    height: 2px;
    width: 100%;
    z-index: -1;
    width: 50%;
    // background: $primary-color;
  }
}

.levels {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: #ddd;
    color: #fff $im;
    float: left;
    border-radius: 50%;
    border: 2px solid #ddd;
    transition: 0.3s all ease;
    position: relative;
    &.active {
      background: $primary-color;
      border-color: $primary-color;
    }
    i {
      font-size: 1.3em;
      line-height: 76px;
    }
    h4 {
      font-size: 1.2em;
      text-transform: uppercase;
    }
  }
}

.tab-content-levels {
  padding: 60px 0;
  text-align: center;
}

.popover {
  border: none;
  border-radius: 6px $im;
  background: $primary-color;
  .popover-header {
    background: transparent;
    border: none;
    color: #fff;
    text-transform: uppercase;
  }
  .popover-body {
    background: transparent;
    color: #fff;
    font-size: 16px;
  }
}

.bs-popover-auto[x-placement^=right]>.arrow::after, .bs-popover-right>.arrow::after {
    border-right-color: $primary-color;
}

.bs-popover-auto[x-placement^=left]>.arrow::after, .bs-popover-left>.arrow::after {
    border-left-color: $primary-color;
}

.form-custom {
  width: 60%;
  margin: 0 auto 80px;
  padding: 15px 20px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
}

#timeline {
  list-style: none;
  position: relative;
  margin-bottom: 80px;
  // &:before {
  //   top: 0;
  //   bottom: 0;
  //   position: absolute;
  //   content: " ";
  //   width: 1px;
  //   background-color: $primary-color;
  //   left: 50%;
  //   margin-left: -1.5px;
  // }
  .clearFix {
    clear: both;
    height: 0;
  }
  .timeline-badge {
    color: #fff;
    width: 16px;
    height: 16px;
    font-size: 1.2em;
    text-align: center;
    position: absolute;
    top: 30px;
    left: 50%;
    margin-left: -9px;
    background-color: $primary-color;
    z-index: 100;
    border-radius: 50%;
  }
  .timeline-movement {
    // border-bottom: dashed 1px #ccc;
    position: relative;
    .timeline-item {
      padding: 20px 0;
      h3 {
        font-size: 18px;
        font-weight: 500;
      }
      p {
        font-size: 15px;
        margin: 10px 0;
      }
      .btn {
        margin: 10px 0 0 $im;
        font-size: 12px;
      }
      .timeline-panel-courses {
        width: 90%;
        min-height: 150px;
        height: auto;
        text-align: left;
        background: #fff;
        padding: 10px;
        border: 1px solid #eee;
        margin-top: 10px;
        border-radius: 6px;
        img {
          float: left;
          margin-right: 10px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
        .progress {
            margin-top: 10px;
            margin-bottom: 10px;
            height: 5px;
          .progress-bar {
            background: $primary-color;
          }
        }
      }
      .timeline-panel-tasks {
        margin-left: 10%;
        text-align: left;
        background: $primary-color;
        color: #fff;
        padding: 10px;
        margin-top: 10px;
        border-radius: 6px;
        .progress {
            margin-top: 10px;
            margin-bottom: 10px;
          .progress-bar {
            background: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #timeline {
    .timeline-panel-courses {
      width: 95% $im;
      margin: 0 auto $im;
      height: auto;
    }

    .timeline-panel-tasks {
      width: 90% $im;
      margin: 0 auto 10px $im;
    }

    .timeline-badge {
      margin-top: -38px $im;
    }

  }
}
