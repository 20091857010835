.categories-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  li {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 130px;
    border-radius: 6px;
    background: #fff;
    color: $primary-color;
    transition: 0.5s;
    cursor: pointer;
    &:hover {
      background: $primary-color;
      color: #fff;
      transition: 0.5s;
    }
    i {
      font-size: 45px;
    }
  }
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    width: 90%;
    height: auto;
    font-size: .9em;
    text-align: center;
    font-weight: 400;
  }
}

.skeleton-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  li {
    display: inline-block;
    width: 130px;
    height: 130px;
    border-radius: 6px;
    list-style: none;
    .box-input__loading--box {
      margin: 0 $im;
    }
  }
}



@media (max-width: 768px) {

  .categories-list {
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
    li {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 30%;
      height: 80px;
      margin: 5px;
      i {
        font-size: 40px;
        margin-top: 10px;
      }
    }
    p {
      display: none;
    }
  }

  .skeleton-list {
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
      li {
        display: inline-block;
        width: 30%;
        height: 80px;
        margin: 5px;
      }
  }


}
