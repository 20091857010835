@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
body {
    background-color: $bg-client $im;
    font-family: 'Roboto', sans-serif;
}

.compress-training,
.compress {
    width: 1024px;
    margin: 0 auto;
    max-width: 100% !important;
}

.content {
    top: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    // padding-top: 8px;
    transition: all 0.3s;
}

.content.active {
    width: -310px;
    min-height: 100vh;
}

.wrapper {
    position: relative;
    top: 56px;
    width: 100%;
    min-height: calc(100vh - 56px);
    height: auto;
    padding: 0;
    overflow: hidden;
    
    &:after {
        display: table;
        clear: both;
        content: " ";
    }
}

.wrapper-auth {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    width: 100%;
    min-height: 100vh;
    height: auto;
    padding: 30px;
    background-repeat: no-repeat;
    background-position: center $im;
    background-size: cover $im;
    .logo {
        width: 200px;
        height: auto;
    }
}

.container-my-page {
    width: 850px;
    margin: 0 auto 40px;
}

.sticky {
    position: sticky;
    top: 80px;
}

.cpanel {
    width: 800px;
    margin: 0 auto;
}

.wrapper-fluid-container {
  .compress-training {
      width: 100% !important;
      padding-left: 5% !important;
      padding-right: 5% !important;
  }
}

@media (max-width: 767px) {

    .wrapper-fluid-container {
        .compress-training {
            width: 100% !important;
            padding-left: 1% !important;
            padding-right: 1% !important;
        }
      }


    .compress {
        width: 100% !important;
        margin: 0 auto;
        padding: 0 5px;
        overflow: hidden;
    }
    .container-my-page {
        width: 100%;
        margin: 0 auto 40px;
    }

    .order-xs-1,.order-sm-1 {
        order: 1;
    }
    .order-xs-2,.order-sm-2 {
        order: 2;
    }
    .order-xs-3,.order-sm-3 {
        order: 3;
    }


}

#page-container {
  margin-top: calc(60px + 2em);
}

