.grid-card-rounded {
  .card {
    border-radius: 8px !important;
  }

  img.card-cover {
    border-radius: 8px 8px 0 0 !important;
  }

  .helper-card {
    svg {
      border-top-right-radius: 8px !important;
    }
  }

  .card-videos {
    .cover {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .card-streaming {
    border-radius: 8px !important;

    img {
      border-radius: 8px !important;
    }
  }

  .card-style-2 {
    .overlay {
      border-radius: 8px !important;
    }
  }

  .border-rounded {
    border-radius: 8px !important;
  }

  .card-training-and-products {
    .cover {
      &:first-child {
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
      }
      &:last-child {
        border-bottom-left-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
      }
    }
  }

  .card-channel-videos {
    .cover {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;

      img {
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
      }

      .overlay {
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
      }
    }
  }

  .cover.card-client.border-radius-top img,
  .design-main-news-section .cover img {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  .card-client {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  .card-product {
    .card-header {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }
  }

  .card-course {
    .cover {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
  }

  .carousel-item .cover {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  .card-contributors {
    ul {
      li {
        &:first-child {
          border-top-left-radius: 8px !important;
          border-top-right-radius: 8px !important;
        }
      }
    }
  }

  div#columns {
    figure {
      border-radius: 8px !important;

      img {
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
      }
    }
  }

  .card-training {
    width: 100%;
    height: auto;
    border-radius: 8px !important;

    img {
      border-radius: 8px !important;
    }
  }

  .card-influencers-two {
    border-radius: 8px !important;
  }

  .card-articles {
    .card-header {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }
  }

  .card-videos {
    .cover {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;

      img {
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
      }

      .border-complete {
        border-radius: 8px !important;
      }
    }
  }

  .card-free-courses {
    img {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }
  }

  .card-regional {
    .cover {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;

      .mask {
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
      }
    }
  }

  .card-channel-videos {
    .cover {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;

      img {
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
      }
    }
  }

  .card-file {
    border-radius: 8px !important;

    .card-cover {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }

    .card-body {
      border-bottom-left-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }

  .card-pure-img {
    img {
      border-radius: 8px !important;
    }
  }

  .card-voucher {
    border-radius: 8px !important;

    img {
      border-radius: 8px !important;
    }

    .overlay {
      border-radius: 8px !important;
    }
  }

  .card-technical {
    border-radius: 8px !important;

    .cover {
      img {
        border-radius: 8px !important;
      }

      .overlay {
        border-radius: 8px !important;
      }
    }
  }

  .card-talk {
    .cover {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;

      img {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
      }
    }
  }

  .card-talks-mini {
    ul {
      li {
        &:last-child {
          border-bottom-left-radius: 8px !important;
          border-bottom-right-radius: 8px !important;
        }

        .cover {
          img {
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
          }
        }
      }
    }
  }

  .card-documents-title {
    border-radius: 8px;

    &.first {
      border-radius: 8px 8px 0 0 !important;
    }

    &.last {
      border-radius: 0 0 8px 8px !important;
    }
  }

  .gallery-profile {
    border-radius: 8px !important;
  }

  .card-live {
    border-radius: 8px !important;

    .cover {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
  }

  .card-live-cover {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  .card-school {
    border-radius: 8px !important;

    .bg-grey-dark {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }

    .row {
      .col-sm-4 {
        img {
          border-top-left-radius: 8px !important;
          border-bottom-left-radius: 8px !important;
        }
      }
    }
  }

  .card-exhibitors {
    border-radius: 8px !important;

    .content {
      border-radius: 8px !important;
    }
  }

  .content-edital {
    border-radius: 8px !important;
  }

  .content-edital .mask {
    border-radius: 8px !important;
  }

  .content-edital-one {
    border-radius: 8px !important;
  }

  .content-edital-one .mask {
    border-radius: 8px !important;
  }

  .content-edital .mask:hover,
  .content-edital-one .mask:hover {
    border-radius: 8px !important;
  }

  .playlist {
    .card-body {
      .thumb-video {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
      }
    }
  }

  .card-sale {
    .cover {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
    }
  }

  .card-journey-item {
    border-radius: 8px !important;
  }

  .border-radius {
    border-radius: 8px !important;
  }

  .carousel-training {
    .cover {
      border-radius: 8px !important;
    }
  }

  .carousel-stories {
    border-radius: 8px !important;
  }

  .form-feed {
    border-radius: 8px !important;
  }

  .feed-card {
    border-radius: 8px !important;
  }

  .btn-round,
  .btn-rounded,
  .btn-feed {
    border-radius: 30px !important;
  }

  // Reescrito em 18/11/2021 -Sérgio Borges

  .generic-card {
    .generic-card-body {
      h4,
      h5 {
        font-weight: 600 !important;
      }

      p {
      }
    }

    .generic-card-cover,
    .generic-card-cover-lg,
    .generic-card-cover-list {
      img {
      }
    }

    .generic-card-cover {
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;

      img {
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;
      }
    }

    .generic-card-cover-lg {
      img {
      }
    }

    .generic-card-cover-list {
      img {
      }
    }
  }

  .generic-card-border-full {
    border-radius: 8px !important;

    .card {
      border-radius: 8px !important;
      margin-bottom: 0 !important;
    }

    img {
      border-radius: 8px !important;
    }
  }

  .generic-card-border-bottom {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  .generic-card-price {
    border-radius: 8px !important;
  }
}
