.form-feed {
  border-radius: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}


.feed-card {
  border-radius: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.card {
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: none;
  margin-bottom: 30px;
  border-radius: 0 !important;
}

// CARD-AUTH
.card-auth {
  // min-height: 350px;
  background: rgba(255, 255, 255);

  .flex {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  a {
    color: $primary-color;

    &:hover {
      color: $primary-color-hover;
    }
  }
}

// CARD-PRODUCT
.card-product {
  width: 233px;
  height: auto;
  margin: 10px auto;
  transition: 0.5s;

  .box-input__loading--box {
    margin: 0 $im;
  }

  .card-header {
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 190px;
    width: 100%;
    padding: 0;
    margin: 0;
    height: 190px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border: none;

    img {
      object-fit: cover;
    }
  }

  .card-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -25px;
    text-align: center;

    img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      padding: 2px;
      border: 2px solid #eee;
      object-fit: cover;
    }
  }

  .card-body {
    text-align: center;

    .store-name {
      height: 40px;
      font-size: 14px;
      text-align: center;
      color: $primary-color;
      text-transform: uppercase;
      font-weight: 500;
      overflow: hidden;
    }

    .product-name {
      height: 40px;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 500;
      color: #333;
      height: 40px;
      overflow: hidden;
    }

    .product-price {
      height: 25px;
      color: $primary-color;
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
    }
  }
}

.card-product-description {
  .box {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 10px;

    .product-name {
      text-transform: uppercase;
      font-size: 17px;
      font-weight: 700;
    }

    .hashtag {
      color: #aaa;
      font-size: 13px;
    }

    .code {
      color: #414141;
      font-size: 13px;
      margin-top: 10px;
    }

    .description {
      font-size: 14px;
      line-height: 1.3;
      color: #666;
    }

    .avatar {
      float: left;
      margin-right: 15px;
      margin-top: -3px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }

    p {
      font-size: 14px;
    }
  }

  .no-border {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .box:last-child {
    border-bottom: none;
  }
}

.card-photos {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  img {
    flex: 1 1 150px;
    height: 100px;
    max-width: 150px;
    margin: 10px 10px 10px 0;
  }
}

.card-about {
  h5 {
    font-size: 18px;
    font-weight: 400;
  }

  p {
    line-height: 1.4;
    font-weight: 400;
    font-size: 15px;
  }
}

// CARD-COMMNENT
.comment-question {
  border-top: 1px solid #eee;
  padding: 15px 0;

  .avatar {
    float: left;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .name {
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
  }

  .date {
    font-size: 12px;
  }

  p {
    margin: 10px 0;
    font-size: 14px;
    color: #555;
    line-height: 1.5;
  }

  a {
    font-size: 14px;
  }
}

.comment-answer {
  padding: 15px 0 15px 30px;

  .avatar {
    float: left;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .name {
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
  }

  .date {
    font-size: 12px;
  }

  p {
    margin: 10px 0;
    font-size: 14px;
    color: #555;
    line-height: 1.5;
  }

  a {
    font-size: 14px;
  }
}

// CARD-COURSE
.card-course {
  height: 280px;
  border: none;

  .cover {
    width: 100%;
    height: 280px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    object-fit: cover;
    margin-bottom: 0;
  }

  .title {
    text-transform: uppercase;
    margin-bottom: 10px;
    height: 40px;
    font-weight: 500;
    font-size: 20px;
  }

  .description {
    margin: 20px 0;
    height: 65px;
    line-height: 1.5;
    overflow-y: auto;
  }

  .progress {
    margin-bottom: 20px;
    height: 5px;

    .progress-bar {
      background: $primary-color;
    }
  }
}

// CARD-INFO
.card-info {
  i {
    float: left;
    font-size: 50px;
    color: $primary-color;
  }

  .category {
    text-align: right;
    margin-bottom: 20px;
  }

  .progress {
    height: 5px;
    margin-bottom: 10px;

    .progress-bar {
      background: $primary-color;
    }
  }

  small {
    font-size: 13px;
    text-transform: uppercase;
  }
}

// CARD-TABLE
.card-table {
  .card-header {
    padding: 15px 15px 10px;
    font-weight: 500;

    .card-title {
      padding: 0;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .card-body {
    padding: 10px 10px 0;

    .table th,
    .table tr {
      text-align: center;
      font-size: 14px;
      line-height: 1.5;
    }

    .badge-primary {
      border-radius: 30px;
      background: $primary-color;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 10px !important;
      padding: 4px 10px;
      line-height: 1.1;
      color: #fff;
    }

    .details {
      color: $primary-color;
      font-size: 16px;
    }
  }
}

// CARD-SPOTLIGHT
.spotlight-title {
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.card-spotlight {
  .avatar {
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 15px;
    margin-top: -3px;
  }

  .card-spotlight-img {
    max-height: 170px;
    min-height: 169px;
  }

  h4 {
    font-size: 16px;
    text-transform: uppercase;
    color: #666;
  }

  p {
    color: $primary-color;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 30px;
    margin-top: 5px;
  }

  .price {
    color: $primary-color;
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 30px;
  }

  .carousel-item .cover {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .carousel-indicators {
    margin-bottom: 10px;

    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin: 0 3px;
      background-color: $primary-color;
    }

    .active {
      opacity: 1;
    }
  }
}

// CARD-CONTRIBUTORS
.card-contributors {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      height: 70px;
      padding: 15px 20px;

      &:first-child {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }

      &:nth-of-type(odd) {
        background: #f9f9f9;
      }

      &:nth-of-type(even) {
        background: #fff;
      }

      &:hover {
        background: #f1f1f1;
      }

      .avatar-40 {
        float: left;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .user {
        margin-top: 7px;

        p {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          color: #555;
        }
      }
    }
  }
}

// CARD-CATEGORIES
.card-categories {
  .title {
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 500;
  }

  ul {
    list-style: none;

    li {
      line-height: 1.7;

      a {
        color: #414141;

        &:hover {
          color: $primary-color $im;
        }
      }
    }
  }
}

//CARD STEPPER
.card-stepper {
  padding: 0;

  .title {
    margin-top: 25px;
    margin-bottom: 15px;
  }

  mat-horizontal-stepper {
    background: transparent $im;

    .mat-horizontal-stepper-header-container {
      display: none $im;
    }

    .mat-horizontal-content-container {
      overflow: hidden;
      padding: 15px;
      text-align: left;

      .question {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 10px 15px;
        border-bottom: 1px solid #eee;

        p {
          font-size: 18px;
          font-weight: 500;
          line-height: 1.3;

          span {
            color: $primary-color;
            font-weight: 900;
          }
        }
      }

      .congratulations {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        i {
          font-size: 50px;
          color: #5cb85c;
          margin-bottom: 20px;
        }

        h5 {
          margin-bottom: 40px;
        }
      }

      .navigation {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 15px;
        border-top: 1px solid #eee;
      }
    }
  }
}

// CARD-HELP-DESK
.card-help-desk {
  min-height: 100px;
  background: rgba(255, 255, 255, 0.7);

  .card-content {
    font-size: 0.9em;
  }

  .table {
    margin-bottom: 0;

    tr {
      td,
      th {
        padding: 0.75rem;
        vertical-align: middle;
      }
    }
  }

  a {
    color: $primary-color;
    cursor: pointer;

    &:hover {
      color: $primary-color-hover;
    }
  }
}

.card-help-desk-msg {
  min-height: 264px;
  background: rgba(255, 255, 255, 0.7);
  padding: 1rem;
  margin: 0 auto 2rem;

  .card-header {
    font-weight: bold;
    padding: 0 0 0.5rem;
    margin-bottom: 0.8rem;
  }

  .card-content {
    font-size: 0.9em;

    p {
      margin-bottom: 1rem;
      white-space: pre-line;
    }
  }

  .comment {
    width: 100%;
    height: 135px;
    border-color: #eee;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
  }

  a {
    color: $primary-color;
    cursor: pointer;

    &:hover {
      color: $primary-color-hover;
    }
  }
}

.card-help-desk-title {
  min-height: 20px;
  background: rgba(255, 255, 255, 0.7);
  padding: 1rem;
  margin: 0 auto 2rem;
  font-weight: bold;
}

.card-help-desk-new {
  min-height: 20px;
  background: rgba(255, 255, 255, 0.7);
  padding: 1rem;
  margin: 0 auto 2rem;

  .card-header {
    font-weight: bold;
    padding: 0 0 0.5rem;
    margin-bottom: 0.8rem;
  }

  p {
    margin-top: 0.75rem;
  }

  ol {
    margin-top: 0.75rem;

    li {
      margin-left: 2rem;
    }
  }

  mat-radio-button {
    margin-right: 2rem;
  }

  mat-form-field {
    margin-top: 1rem;
  }

  a {
    color: $primary-color;
    cursor: pointer;

    &:hover {
      color: $primary-color-hover;
    }
  }
}

// HERLPER CARD
.helper-card {
  position: relative;
  bottom: 0;
  right: 0;
}

.helper-svg {
  fill: $primary-color;
  color: #eceff1;
  position: absolute;
  bottom: 0;
  border-radius: 0;
  border: 0;
  right: 0;
  border-top-right-radius: 0;
  transform: rotate(90deg);
}

.helper-svg-text {
  fill: #fff;
  font-size: 90px;
  transform: rotate(-90deg);
}

// PORTFOLIOs
#columns {
  column-width: 210px;
  column-gap: 15px;
  width: 100%;
  max-width: 1000px;
}

div#columns {
  figure {
    cursor: pointer;
    background: #fefefe;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    margin: 0 2px 15px;
    padding: 0 0 10px;
    transition: opacity 0.4s ease-in-out;
    display: inline-block;
    column-break-inside: avoid;
    border-radius: 0 !important;

    img {
      width: 100%;
      height: auto;
      margin-bottom: 5px;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

    figcaption {
      color: #444;
      padding: 15px;

      h4 {
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      p {
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
}

.card-plans {
  text-align: center;

  .card-body {
    height: 450px;

    .title {
      color: #999;
      text-transform: uppercase;
      margin: 10px 0 0;
    }

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 120px;
      margin: 15px auto;
      border: 1px solid #eee;
      border-radius: 50%;

      i {
        font-size: 50px;
        color: $primary-color;
      }
    }

    h5 {
      text-transform: uppercase;
      margin-bottom: 30px;
      color: #414141;
    }

    p {
      margin-bottom: 30px;
      color: #999;
      font-size: 0.9em;
    }
  }
}

.card-order {
  .card-header {
    display: flex;
    align-items: center;
    margin: 5px 0 0;
    font-weight: 500;
  }

  .card-body {
    p {
      margin-bottom: 10px;
      font-size: 14px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .table {
      margin: 0;

      td,
      th {
        font-size: 14px;
      }
    }
  }
}

.bg-transparent {
  background: transparent $im;
}

@media screen and (max-width: 750px) {
  #columns {
    column-gap: 0;

    figure {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .card-course {
    height: auto;

    .pl-0 {
      margin-left: 15px;
    }

    .cover {
      width: 100%;
      height: 190px;
    }
  }

  .card-product {
    width: 100% $im;

    .card-avatar {
      margin-top: -40px;

      img {
        width: 80px;
        height: 80px;
      }
    }
  }
}

.card-menu {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
      height: 50px;
      border-bottom: 1px solid #eee;

      a {
        display: block;
        width: 100%;

        i {
          font-size: 20px;
          margin-right: 15px;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.featured-image-item {
  width: 100%;
  height: 220px;
  object-fit: cover;
}

.featured-title {
  color: #444;
  font-size: 16px;
  text-transform: uppercase;
  margin: 5px 0;
}

.featured-price {
  color: var(--primary-color);
  font-size: 16px;
  margin-top: 5px;
}

.mat-card-image {
  margin: 0 -16px 11px !important;
}

.is-liked {
  color: var(--main-color) !important;
}

.mat-card-video {
  width: calc(100% + 32px) !important;
  margin: 0 -16px 11px !important;
}

.hovered {
  cursor: pointer;
  opacity: 1 !important;
}

.card-file {
  width: 100% !important;
}

.card {
  border-radius: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: none;
  margin-bottom: 30px;

  .parallelogram {
    position: relative;
    top: -35px;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background: var(--main-color);
    margin-bottom: -35px;
    -webkit-transform: skew(-30deg);
    -moz-transform: skew(-30deg);
    -ms-transform: skew(-30deg);
    -o-transform: skew(-30deg);
    transform: skew(-30deg);
    text-align: center;

    p {
      color: #fff;
      text-transform: uppercase;
      font-size: 0.85em;
      font-weight: 600;
      letter-spacing: 2px;
      -webkit-transform: skew(30deg);
      -moz-transform: skew(30deg);
      -ms-transform: skew(30deg);
      -o-transform: skew(30deg);
      transform: skew(30deg);
    }
  }

  .cover {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    text-align: center;
    object-fit: cover;
    z-index: 1;

    .overlay {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
      top: 0;
      left: 0;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.5);
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      z-index: 2;

      .play {
        width: 60px;
        height: 60px;
      }

      &:hover {
        cursor: pointer;
        opacity: 1;
      }

      h4 {
        font-size: 1.4em;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
        -moz-text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
        -webkit-text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
      }
    }
  }

  .icons-comments {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
  }

  .list {
    margin: 0;
    padding: 0;

    h3 {
      padding: 0 15px;
      font-size: 1.1em;
      margin: 0;
      text-transform: uppercase;
    }

    li {
      padding: 5px 15px;

      &:nth-of-type(odd) {
        background: #fff;
      }

      &:nth-of-type(even) {
        background: #f9f9f9;
      }

      &:hover {
        background: #f4f4f4;
      }

      small {
        font-size: 0.8em;
        color: #3c4858;
      }

      p {
        font-size: 1em;
        margin: 5px 0;
        padding: 0;
        color: #3c4858;
      }

      .title {
        font-size: 0.9em;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }

  .helper-card {
    position: relative;
    bottom: 0;
    right: 0;

    svg {
      fill: var(--bg-btn);
      color: var(--text-btn);
      position: absolute;
      bottom: 0;
      border-radius: 0;
      border: 0;
      right: 0;
      border-top-right-radius: 0;
      transform: rotate(90deg);
    }

    text {
      fill: #fff;
      font-size: 90px;
      transform: rotate(-90deg);
    }
  }
}

.card-title {
  margin-top: 20px;

  .row {
    min-height: 120px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .col-sm-6 {
      height: 100%;
    }

    h2 {
      text-transform: uppercase;
      font-weight: 600;
    }

    h3 {
      text-transform: uppercase;
      font-weight: 600;
      color: var(--main-color);
    }

    p {
      font-weight: 500;
      font-size: 16px;

      strong {
        color: var(--main-color);
        text-transform: uppercase;
      }
    }
  }
}

.card-default {
  width: 100%;

  h4 {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
    color: #3c4858;
  }

  p {
    color: #bbb;
  }

  .col-sm-5 {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      object-fit: cover;
    }
  }
}

.card-training {
  width: 100%;
  height: auto;
  border-radius: 0 !important;

  .card-body {
    padding-left: 0;
    padding-right: 30px $im;
    padding-bottom: 0 $im;
  }

  img {
    border-radius: 0 !important;
    height: auto;
    margin-bottom: 0 $im;
  }

  h4 {
    width: 100%;
    min-height: 40px;
    font-size: 1.4em;
    font-weight: 600;
    text-transform: uppercase;
    word-wrap: break-word;
    color: #3c4858;
  }

  .description {
    color: #bbb;
  }

  // .progress {
  //   height: 8px;
  //   border-radius: 30px;
  //   .progress-bar {
  //     background: var(--main-color);
  //   }
  // }
}

.card-skills {
  margin: 6px 8px 30px;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 60%;
      margin: 20px 0 10px;
    }

    h4 {
      font-size: 20px;
      height: 50px;
      overflow: hidden;
    }
  }

  .card-body {
    .progress {
      height: 8px;
      border-radius: 30px;

      .progress-bar {
        background: var(--main-color);
      }
    }
  }
}

.card-influencers {
  margin: 6px 8px;

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    height: 70px;
    padding: 10px;

    .avatar {
      width: 35px $im;
      height: 35px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .no-avatar {
      float: left;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 10px;
      color: #888;
      background: #eee;
      padding: 7px;

      i {
        font-size: 1.6em;
      }
    }

    h4 {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .cover {
    height: 250px;

    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

// .card-home-skills {
// }
.card-influencers-two {
  height: auto;
  transition: 0.5s;
  border-radius: 0 !important;
  background: #fff;
  padding: 0px;
  max-width: 250px;
  margin: 0 auto;
  float: none;
  min-height: 285px;

  .card-body {
    color: #3c4858;

    .avatar {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      margin: 15px auto 20px;
      object-fit: cover;
      border: 2px solid var(--main-color);
    }

    .titulo {
      text-transform: uppercase;
      height: 70px;
      font-size: 16px;
      color: #3c4858;
    }

    .descricao {
      height: 100px;
      text-transform: normal;
      color: #c0c0c0;
    }
  }

  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: none;
    color: #3c4858;
    background: transparent;
    font-size: 14px;

    a {
      color: #3c4858;

      &:hover {
        color: var(--main-color);
      }
    }
  }
}

.card-articles {
  cursor: pointer;

  .card-header {
    font-weight: 600;

    h3 {
      font-size: 18px;
      line-height: 1.5;
    }
  }

  .card-header,
  .card-body {
    display: flex;
    align-items: center;
    height: 180px $im;

    h4 {
      font-size: 18px;
    }

    p {
      color: #bbb;
    }
  }

  .card-header {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    justify-content: center;
    text-align: center;
  }
}

.card-videos {
  cursor: pointer;

  .cover {
    width: 100%;
    height: 160px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      object-fit: cover;
    }

    .timer {
      position: absolute;
      bottom: 5px;
      right: 5px;
      padding: 2px 5px;
      background: rgba(0, 0, 0, 0.8);
      color: #fff;
      font-size: 0.7em;
      border-radius: 2px;
      z-index: 1;
    }

    .border-complete {
      border-radius: 0 !important;
    }
  }

  .btn-edit {
    position: absolute;
    top: 170px;
    right: 0;
  }

  .card-body {
    .avatar {
      float: right;
      margin-left: 15px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    .avatar-null {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 10px 12px;
      color: #888;
      background: #eee;
      float: right;
    }

    h4 {
      width: 95%;
      height: 35px;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      word-wrap: break-word;
      font-size: 500;
      text-align: left;
      overflow: hidden;
      color: #3c4858;
    }

    p {
      color: #bbb;
    }

    .btn-actions {
      position: absolute;
      top: 160px;
      right: 0;
      color: #333;
    }
  }
}

.btn-actions {
  color: black !important;

  button {
    background-color: transparent !important;
    border: none;
  }
}

.card-video-large {
  .video {
    width: 100%;
    height: 380px;
  }

  .card-body {
    .avatar {
      float: right;
      margin-left: 15px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .avatar-null {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      padding: 10px 12px;
      color: #888;
      background: #eee;
      float: right;
    }

    h4 {
      font-size: 20px;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 15px;
    }
  }
}

.card-comments {
  input[type="text"] {
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    background: #f5f5f5;
    border: transparent;
    width: 100%;
    margin-top: 8px;
    padding: 2px 10px;
  }

  .btn-send {
    position: absolute;
    right: 15px;
    top: 15px;
    border: 0;
    outline: none;
    background: transparent;
    color: #aaa;

    &:hover {
      cursor: pointer;
    }
  }

  .comment {
    margin-bottom: 30px;

    .avatar {
      float: left;
      margin-right: 15px;
      margin-left: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .avatar-null {
      float: left;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: #888;
      background: #eee;
    }

    h4 {
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    p {
      color: #3c4858;
    }

    .btn-edit {
      float: right;
    }
  }
}

.media {
  // padding: 20px;
  background: black;
  color: #3c4858;
  // border-radius: 10px;
  background-image: url();

  .text {
    font-size: 1em $im;
  }
}

.card-free-courses {
  margin: 6px 8px 30px;

  img {
    width: 100%;
    height: 160px;
    object-fit: cover;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  h4 {
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 0 10px;
    height: 50px;
    overflow: hidden;
    font-size: 16px;
    color: #3c4858;
  }

  p {
    height: 70px;
    overflow: hidden;
    color: #bbb;
  }
}

.card-leader {
  .avatar {
    float: left;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 20px;
  }

  .no-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 20px;
    color: #888;
    background: #eee;

    i {
      font-size: 3em;
    }
  }

  .user-data {
    margin-top: 15px;

    h4 {
      font-size: 1.2em;
      font-weight: 600;
      line-height: 1;
      white-space: nowrap;
    }

    p {
      font-size: 0.9em;
    }

    .progress {
      margin: 15px 0 0;
    }
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 130px;

    li {
      text-align: center;
      margin: 0 15px;

      img {
        width: auto;
        height: 40px;
      }

      p {
        font-weight: 600;
      }
    }
  }
}

.card-regional {
  .cover {
    width: 100%;
    height: 160px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    .mask {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      text-align: center;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      text-transform: uppercase;
      font-size: 2em;
      color: white;
      text-shadow: 0 0 5px black;
      font-weight: 600;
    }
  }

  .avatar {
    float: left;
    margin-right: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .no-avatar {
    float: left;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #888;
    background: #eee;
    padding: 4px;

    i {
      font-size: 2em;
    }
  }

  .points {
    width: auto;
    height: 50px;
  }
}

.card-progress {
  .card-body {
    font-size: 13px;

    h5 {
      font-size: 15px;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
    }
  }
}

.card-client {
  background: var(--main-color);
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.card-channel-videos {
  cursor: pointer;

  .card-body {
    h4:hover {
      color: var(--main-color);
      transition: 0.3s;
    }
  }

  .cover {
    width: 100%;
    height: 120px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      object-fit: cover;
    }

    .timer {
      position: absolute;
      bottom: 5px;
      right: 5px;
      padding: 2px 5px;
      background: rgba(0, 0, 0, 0.8);
      color: #fff;
      font-size: 0.7em;
      border-radius: 2px;
      z-index: 1;
    }
  }

  .card-body {
    h4 {
      width: 100%;
      min-height: 40px;
      font-size: 14px;
      text-transform: uppercase;
      word-wrap: break-word;
      font-size: 500;
    }

    p {
      color: #bbb;
      height: auto;
    }
  }
}

.card-file {
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: none;
  border-radius: 16px;
  transition: all 0.5s;
  margin-bottom: 30px;
  cursor: pointer;

  .card-cover {
    display: block;
    align-items: center;
    text-align: center;
    padding: 15px;
    position: relative;
    width: 100%;
    height: 100px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    object-fit: cover;
    z-index: 1;
    background: var(--main-color);
    color: #fff $im;

    .title {
      color: #fff;
      margin-bottom: 0;
      height: 60px;
      font-size: 18px;
    }
  }

  .circle {
    float: left;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: -30px 10px 15px 15px;
    background: #eee;
    z-index: 1;
    font-size: 1.5em;
    color: #3c4858 !important;
  }

  .card-body {
    height: 125px;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    .edit-file {
      float: right $im;
      margin-top: -60px;
      margin-right: -15px;
    }

    h5 {
      height: 60px;
    }

    .description {
      color: #888;
      height: 65px;
      overflow: hidden;
    }
  }
}

.card-pure-img {
  width: 100%;
  height: 270px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0 !important;
  }
}

.card-points {
  text-align: center;

  img {
    width: 80px;
    height: auto;
  }

  .box-points {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: auto;
    margin: 10px 0 20px;

    h1 {
      font-weight: 600;
      color: var(--main-color);
      font-size: 2.3em;
      margin-right: 10px;
      margin-top: 0px;
    }

    h5 {
      text-align: left;
      font-weight: 600;
      color: #3c4858;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 1.4em;
    }

    p {
      text-align: left;
      font-weight: 500;
      color: #3c4858;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 0.77em;
      margin-top: -5px;
    }
  }
}

.card-credits {
  text-align: center;

  img {
    width: 80px;
    height: auto;
  }

  .box-credits {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: auto;
    margin: 10px 0 20px;

    h1 {
      margin: 2px;
      font-weight: 600;
      color: var(--main-color);
      font-size: 2em;
      margin-right: 10px;
      margin-top: 0px;
    }

    h5 {
      text-align: left;
      font-weight: 600;
      color: #3c4858;
      text-transform: uppercase;
      letter-spacing: 3px;
      font-size: 1.2em;
    }

    p {
      text-align: left;
      font-weight: 500;
      color: #3c4858;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 0.77em;
      margin-top: -5px;
    }
  }
}

.card-gain {
  img {
    width: auto;
    height: 100px;
    object-fit: contain;
    margin: 10px 0;
  }
}

.card-menu-forum {
  .list {
    margin-top: -3px;
    list-style: none;

    .title {
      height: 40px;
      text-align: left;
      line-height: 20px;
      @include line-truncate(2);
    }

    li {
      padding-bottom: 10px;
    }

    .question {
      margin-top: -15px $im;
      font-size: 0.9em;
    }
  }
}

.card-contribuitors {
  ul {
    li {
      display: flex;
      align-items: center;
      height: 90px;

      &:nth-of-type(odd) {
        background: #f9f9f9;
      }

      &:nth-of-type(even) {
        background: #fff;
      }

      &:hover {
        background: #f4f4f4;
      }

      > .avatar {
        width: 45px;
        height: 45px;
        margin: 0 10px;
        border-radius: 50%;
      }

      .no-avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        margin: 0 10px;
        border-radius: 50%;
        color: #888;
        background: #eee;
        font-size: 1.5em;
      }

      > .text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 150px;
        height: 100%;

        h4 {
          font-weight: 600;
          text-transform: uppercase;
          font-size: 0.9em;
          color: var(--main-color);
          margin: 0;
        }

        h5 {
          font-weight: 600;
          text-transform: uppercase;
          font-size: 0.8em;
          margin: 0;
        }

        p {
          font-size: 0.75em;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

.card-contribuitors-large {
  .user {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 60px;

    .avatar {
      float: left;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 15px;
      object-fit: cover;
    }

    .no-avatar {
      float: left;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 15px;
      color: #888;
      background: #eee;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 2em;
      }
    }

    .data {
      h4 {
        font-size: 16px;
        text-transform: uppercase;
      }

      p {
        text-transform: capitalize;
      }
    }
  }

  .points {
    ul {
      margin-top: 20px;

      li {
        display: inline-block;
        width: 50%;
        text-align: center;

        img {
          width: auto;
          height: 40px;
        }

        p {
          font-weight: 600;
        }
      }
    }
  }
}

.card-training-and-products {
  .color {
    background-color: var(--main-color);
    border-bottom: 1px solid white;
    border-radius: 0 !important;
  }

  .cover {
    height: 180px;
    width: 100%;

    img {
      width: 100%;
      height: 180px;
      object-fit: cover;
    }
  }

  .border-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .border-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .border-center {
    border-radius: none !important;
  }

  .border-complete {
    border-radius: 0 !important;
  }
}

.card-voucher {
  border-radius: 10px;

  .col-sm-6 {
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
  }

  .overlay {
    border-radius: 10px;
  }
}

.card-technical {
  border-radius: 10px;

  .cover {
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }

    .overlay {
      border-radius: 10px;
    }
  }
}

.card-team {
  .btn-delete {
    position: absolute;
    right: 7px;
    top: 7px;

    .fa {
      font-size: 18px;
      color: #ff0000;
    }
  }

  .card-body {
    width: 100%;
    height: 210px;
    margin-bottom: 10px;
    text-align: center;

    .avatar {
      width: 120px;
      height: 120px;
      margin: 0 0 15px;
      border-radius: 50%;
      object-fit: cover;
    }

    h4 {
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 5px;
    }

    p {
      font-size: 11px;
    }
  }
}

.ribbon {
  position: absolute;
  right: 10px;
  top: 10px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 2px;
  background: var(--main-color) !important;
  color: #fff !important;
  padding: 3px 5px;
  border-radius: 4px;
  z-index: 50;
}

.card-streaming {
  border-radius: 0 !important;
  margin: 10px 12px !important;

  img {
    width: 100%;
    height: 120px;
    border-radius: 0 !important;
    object-fit: cover;
  }

  small {
    color: var(--main-color);
  }

  h5 {
    font-size: 16px;
    margin: 5px 0 10px;
  }

  p {
    line-height: 1.1;
    font-size: 14px;
  }
}

.card-talk {
  cursor: pointer;

  .cover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    object-fit: cover;
    background: #f9f9f9;

    i {
      font-size: 40px;
      color: #888;
    }

    img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      object-fit: cover;
    }
  }

  .col-5 {
    display: flex;
    align-items: center;
    justify-content: center;

    .group {
      width: 60px;
      height: 60px;

      img {
        float: left;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 0;
        object-fit: cover;
      }

      .bullet {
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: var(--main-color);
        font-size: 0.7em;
        color: #fff;
        font-weight: 600;
      }
    }
  }

  .col-7 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    height: 80px;
    padding: 10px;

    .title {
      width: 100%;
      font-size: 15px;
      font-weight: 500;
      @include line-truncate(2);
    }

    .number {
      font-size: 12px;
    }
  }
}

.card-talks-mini {
  ul {
    li {
      display: flex;
      align-items: center;
      height: 80px;

      &:nth-of-type(odd) {
        background: #f9f9f9;
      }

      &:nth-of-type(even) {
        background: #fff;
      }

      &:last-child {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }

      &:hover {
        background: #f4f4f4;
      }

      .cover {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        margin: 0 10px;
        border-radius: 50%;
        object-fit: cover;
        background: #f1f1f1;

        i {
          font-size: 25px;
          color: #888;
        }

        img {
          width: 100%;
          height: 100%;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          object-fit: cover;
        }
      }

      .group {
        width: 50px;
        height: 50px;
        margin: 10px 10px 0;

        img {
          float: left;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          margin: 0;
          object-fit: cover;
        }

        .bullet {
          display: flex;
          align-items: center;
          justify-content: center;
          float: left;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          margin: 0;
          background: var(--main-color);
          font-size: 0.6em;
          color: #fff;
          font-weight: 600;
        }
      }

      .text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 150px;
        height: 100%;
        margin-top: 5px;

        h5 {
          width: 100%;
          font-weight: 600;
          text-transform: uppercase;
          font-size: 0.8em;
          margin: 0;
          @include line-truncate(2);
        }

        p {
          font-size: 0.75em;
          margin: 0;
          padding: 0;
          color: #999;
          @include line-truncate(1);
        }
      }
    }
  }
}

.max-card-height {
  height: 300px;
  max-height: 300px;
}

.stage .card {
  padding: 0px;
  min-width: 10px;
  max-width: 250px;
  margin: 0 auto;
  float: none;
  min-height: 285px;
}

.home-training-container {
  h5.card-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    width: 100%;
    text-align: center;
    margin-top: 0 !important;
    height: 54px;
    line-height: 18px;
    @include line-truncate(3);
  }

  .card-text {
    line-height: 21px;
    height: 105px;
  }

  .card-body {
    height: auto !important;
  }

  img.card-cover {
    height: 150px;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
  }

  .card-carousel-title {
    text-transform: uppercase;
    margin-bottom: 10px;
    @include line-truncate(2);
  }

  .card-carousel-description {
    @include line-truncate(4);
  }

  .carousel-item-card-mini {
    img.card-cover {
      width: 110px;
      height: 110px;
      margin: 30px auto 1px;
    }

    .card-title {
      font-size: 24px;
      height: 24px;
      line-height: 24px;
    }

    .card-text {
      display: none;
    }
  }
}

.card-text-5-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.card-text-5-lines {
  @include line-truncate(5);
}

.card-style-2 {
  .overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    z-index: 2;
    border-radius: 0 !important;

    .play {
      width: 60px;
      height: 60px;
    }

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  .card-style-2-text {
    @include line-truncate(2);
    font-size: 17px !important;
    line-height: 18px;
  }

  .card-style-2-title {
    height: 36px;
    font-weight: bold;
  }

  .card-style-2-subtitle {
    @include line-truncate(3);
    height: 54px;
  }
}

.card-documents {
  min-height: auto !important;
}

.border-radius-none {
  border-radius: 0 !important;
}

.card-documents-title {
  border-radius: 10px;
  height: 150px;

  &.first {
    border-radius: 0 !important;
  }

  &.last {
    border-radius: 0 !important;
  }

  h4 {
    width: 100%;
  }
}

.cover-profile {
  position: relative;

  .event-cover-img-bluered {
    transform: scale(1.2);
    filter: blur(20px) brightness(80%);
  }

  .event-img-profile {
    position: absolute !important;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    width: 100px !important;
    height: 100px !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-radius: 100%;
  }
}

.gallery-profile {
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  .gallery-profile-bg {
    transform: scale(1.2);
    filter: blur(20px) brightness(80%);
  }

  .gallery-profile-photo {
    position: absolute !important;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    width: 100px !important;
    height: 100px !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    border-radius: 100%;
  }
}

.card-live {
  border-radius: 10px;
  background: var(--main-color);
  color: #fff;
  margin-bottom: 0 !important;

  .col-sm-5 {
    padding-left: 0;
  }

  .col-sm-7 {
    padding-right: 0;
  }

  .cover {
    width: 100%;
    height: 300px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .overlay {
    opacity: 0;

    &:hover {
      opacity: 0.7;
    }
  }

  h4 {
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
  }

  .description {
    font-size: 0.8em;
    opacity: 0.7;
    margin-bottom: 10px;
  }

  .author {
    font-size: 0.9em;
    margin-bottom: 10px;
  }

  .text {
    font-size: 0.9em;
    margin-bottom: 10px;
  }

  .chip {
    position: absolute;
    bottom: 20px;
    min-width: 100px;
    max-width: 120px;
    background: #515151;
    color: #fff;
    font-size: 0.7em;
    padding: 2px 7px;
    border-radius: 4px;
    text-align: center;
    margin-top: 15px;
  }
}

.card-live-cover {
  position: relative;
  width: 100% !important;
  height: 300px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  object-fit: cover;
  background: var(--main-color);

  .card-live-cover-bg {
    filter: blur(20px) brightness(80%);
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scale(1.2);
  }

  .card-live-cover {
    object-fit: cover;
    position: absolute;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    width: 200px !important;
    height: 200px !important;
    border-radius: 100%;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }

  .card-live-cover-full {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.card-school {
  border-radius: 0 !important;
  margin-bottom: 30px;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;

  .bg-grey {
    background: #f4f4f4 !important;
  }

  .bg-grey-dark {
    background: #eee !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .row {
    margin-left: 0;
    margin-right: 0;

    .col-sm-3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 180px;

      .content-girl {
        position: relative;
        margin-top: 15px;

        .girl {
          width: 48%;
          height: auto;
        }

        .body-girl {
          stroke-miterlimit: 10;
          opacity: 1;
        }

        .activedSVG {
          opacity: 1;
        }

        .body-font {
          font-family: "Roboto-Black";
        }

        .body-size {
          font-size: 8px;
          text-align: center;
        }

        .percentual-text {
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          left: 50%;
          color: #fff;
        }
      }

      .text-level {
        margin-bottom: 15px;
        margin-top: 5px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center $im;
      }

      .price {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .stamp {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 15px;

        img {
          width: 50%;
          height: auto;
        }

        .text {
          position: absolute;
          z-index: 2;
          transform: rotate(-14deg);
          -webkit-transform: rotate(-14deg);
          -moz-transform: rotate(-14deg);
          -o-transform: rotate(-14deg);
          margin-top: 2px;
          margin-left: 2px;
          font-size: 13px;
          font-weight: 600;
        }
      }

      .buttons {
        height: auto;
      }
    }

    .col-sm-4 {
      height: 180px;
      margin: 0;
      padding: 0;

      img {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .card-main {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      .title {
        height: 55px;
        margin-bottom: 15px;
        padding: 15px 0;
        line-height: 1.15;
        font-size: 1.2em;
        font-weight: 600;
        text-transform: uppercase;
        @include line-truncate(3);
      }

      .description {
        height: 85px;
        font-size: 0.9em;
        @include line-truncate(4);
      }
    }

    .col-sm-9 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        max-height: 55px;
        margin-bottom: 15px;
        padding: 15px 0;
        line-height: 1.15;
        font-size: 1.2em;
        font-weight: 600;
        text-transform: uppercase;
        overflow: hidden;
      }
    }
  }
}

.card-exhibitors {
  border-radius: 0 !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: none;
  margin-bottom: 30px;
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .content {
    border-radius: 0 !important;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;

    .header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 40%;

      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        border-radius: 4px;
        background: #fff;
        padding: 5px;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    .body {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: 60%;

      .title {
        margin-top: 10px;
        font-size: 15px;
        font-weight: 500;
        color: #f0f0f0;
      }

      .description {
        margin-top: 10px;
        font-size: 12px;
        color: #ddd;
      }
    }
  }
}

.card-request {
  width: 100%;
  padding: 5px;
  margin-bottom: 15px;
  height: 80px;
  border: 1px solid #ccc;

  img {
    float: left;
    width: 100px;
    height: 100%;
    object-fit: cover;
    margin-right: 10px;
  }

  .text {
    display: flex;
    align-items: flex-start;
    height: 80px;
    flex-direction: column;
    h5 {
      font-size: 1em;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.2em !important;
      color: #0f9200;
      line-height: 1;
    }
  }

  .delete {
    display: none;
    float: right;
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin-top: -10px;
    margin-right: -14px;
    background: $primary-color;
    text-align: center;
    cursor: pointer;
    i {
      color: #fff;
      font-size: 0.7em;
      line-height: 1 !important;
      margin: 0 !important;
    }
  }

  &:hover {
    border: 1px solid $primary-color;
    .delete {
      display: block;
    }
  }
}

.card-payment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  padding: 15px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  h4 {
    color: $primary-color;
    font-size: 1.2em;
  }
  .material-icons {
    font-size: 1.5em;
    color: $primary-color;
    line-height: 1;
  }
  &:hover {
    border: 1px solid $primary-color;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .card-training {
    width: 100%;
    height: auto;
    border-radius: 0 !important;
    background: #fbfbfb;

    .card-body {
      max-height: auto;
      padding-left: 30px;
      padding-right: 30px $im;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    img {
      width: 100%;
      height: auto;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 10px;
    }
  }

  .card-skills {
    .card-body {
      text-align: center;
    }
  }

  .card-influencers-two {
    width: 80%;
  }

  .card-technical {
    .cover {
      height: 120px;
    }
  }

  .card-training-and-products {
    .cover {
      height: 230px;
      width: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .card-leader {
    text-align: center;

    .avatar {
      float: none;
      display: inline-flex;
      width: 110px;
      height: 110px;
      margin: 10px auto;
    }

    .no-avatar {
      float: none;
      width: 110px;
      height: 110px;
      margin: 10px auto 15px;

      i {
        font-size: 4em;
      }
    }

    .user-data {
      margin-top: 0;
    }

    ul {
      display: block;
      width: 100%;
      height: auto;

      li {
        float: left;
        width: 33.3333%;
        text-align: center;
        margin: 0 0 15px;

        img {
          width: auto;
          height: 40px;
        }

        p {
          font-weight: 600;
        }
      }
    }
  }

  .card-live {
    .col-sm-5 {
      padding-left: 15px;
    }

    .col-sm-7 {
      padding-right: 15px;
    }

    .text {
      margin-bottom: 20px;
    }

    .cover {
      width: 100%;
      height: 300px;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .chip {
      position: relative;
      bottom: 10px;
      margin-top: 30px;
    }
  }

  .card-streaming {
    img {
      width: 100%;
      height: 200px;
    }
  }
}

.card-cover-text {
  height: 120px;
}

.content-edital {
  height: 180px !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--main-color);
  color: #fff;
  border-radius: 0 !important;
}

.content-edital .mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px 15px;
  border-radius: 12px;
  transition: all 0.3s;
  text-align: center;
  border-radius: 0 !important;
}

.content-edital-one {
  height: 220px !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-color: var(--main-color);
  color: #fff;
  border-radius: 0 !important;
}

.content-edital-one .mask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  padding: 15px 30px;
  border-radius: 12px;
  transition: all 0.3s;
  text-align: center;
  border-radius: 0 !important;
}

.content-edital .mask:hover,
.content-edital-one .mask:hover {
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.3s;
  border-radius: 0 !important;
}

.mat-tab-live {
  .mat-tab-label {
    min-width: 40px;

    .mat-tab-label-content {
      flex-direction: column !important;

      small {
        font-size: 9px;
        font-weight: 600;
      }
    }
  }
}

.card-live {
  margin-bottom: 30px !important;
  color: #4d5154;

  .border-right {
    border-right: 1px solid #eee !important;
  }

  small {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
  }

  h6 {
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
  }

  .user {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .avatar {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
      background: var(--main-color);
    }

    .avatar-icon {
      padding: 3px;
    }

    .name {
      font-size: 14px;
      margin-top: 15px;
    }

    .company {
      font-size: 11px;
      color: var(--main-color);
    }
  }

  .time {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .card-live {
    .user {
      margin-bottom: 20px;
    }

    .col-sm-2 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .time {
        margin-bottom: 0;
      }
    }
  }
}

.card-list {
  img {
    float: left;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.card-footer-auth {
  border-top: 1px solid #ccc;
  padding-top: 15px;
  margin-top: 15px;
}

.playlist {
  .card-body {
    width: 100%;
    height: 100px;

    .thumb-video {
      width: 100%;
      height: 100px;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      object-fit: cover;
      object-position: center;
    }

    .col-7 {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      a {
        color: #3c4858;

        &:hover {
          color: var(--main-color);
        }
      }

      h5 {
        font-size: 15px;
        margin: 0;
        padding: 0;
        font-weight: 500;
      }

      p {
        font-size: 13px;
        color: #b1b1b1;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.card-sale {
  .cover {
    width: 100%;
    height: 120px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
  }
  h5 {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  h6 {
    font-weight: 600;
    color: var(--main-color);
  }
  p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .avatar-group {
    display: flex;
    margin-bottom: 10px;

    img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      object-fit: cover;
      margin-left: -5px;
      border: 2px solid #fff;
      &:first-child {
        margin-left: 0;
      }
    }
    .plus {
      background: var(--main-color);
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-left: -5px;
      color: #fff;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .small {
    margin-top: -10px;
  }
}

.border-radius {
  border-radius: 10px;
}

.card-events {
  height: 260px;
  margin: 0 10px 10px !important;

  .card-events-datetime {
    margin-top: -5px;
    margin-bottom: 0 !important;
    font-size: 12px;
    height: 15px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: 1;
  }

  p.card-events-description {
    margin-top: 10px;
    margin-bottom: 0 !important;
    font-size: 14px;
    height: 65px !important;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .title {
    h4 {
      font-size: 16px;
      height: 60px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-height: 1.2;
    }
  }
}

.cover.card-client.border-radius-top img,
.design-main-news-section .cover img {
  height: 100%;
  object-fit: cover;
}

.design-main-evento-sessao .carousel-item-card .card-body {
  height: 190px;
}

.rating-container {
  background: #eee;
  padding: 2em;
  margin-top: 2em;

  .rating-text {
    font-size: 21px;
  }

  .btn {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .rating-container {
    background: #eee;
    padding: 2em;
    margin-top: 2em;

    .rating-text {
      font-size: 16px;
    }

    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      margin-left: 10px;
    }
  }
}

.design-main-evento-sessao .card-live .description {
  @include line-truncate(4);
}
