@font-face {
    font-family: 'Roboto';
    font-weight: 100;
    font-display: swap;
    src: url(./Roboto-Thin.ttf) format('woff2');
  }
  @font-face {
    font-family: 'Roboto';
    font-weight: 300;
    font-display: swap;
    src: url(./Roboto-Light.ttf) format('woff2');
  }
  @font-face {
    font-family: 'Roboto';
    font-weight: 400;
    font-display: swap;
    src: url(./Roboto-Regular.ttf) format('woff2');
  }
  @font-face {
    font-family: 'Roboto';
    font-weight: 500;
    font-display: swap;
    src: url(./Roboto-Medium.ttf) format('woff2');
  }
  @font-face {
    font-family: 'Roboto';
    font-weight: 600;
    font-display: swap;
    src: url(./Roboto-Bold.ttf) format('woff2');
  }
  @font-face {
    font-family: 'Roboto';
    font-weight: 700;
    font-display: swap;
    src: url(./Roboto-Black.ttf) format('woff2');
  }