asap-home-components-header {
    display: flex !important;
    justify-content: center;
}

/* Textos */

.background-blur {
    filter: blur(6px) !important;
}

.block-ui-wrapper {
    background: var(--main-color) !important;
    opacity: 0.9 !important;
}

// SweetAlert Custom
.swal2-styled.swal2-confirm {
    background-color: var(--main-color) !important;
    border-radius: 1.25em !important;
}

.swal2-styled.swal2-cancel {
    border-radius: 1.25em !important;
    background-color: rgb(115, 115, 115) !important;
}

.swal2-popup {
    border-radius: 0.8em !important;
}

.swal2-container.swal2-shown {
    background-color: #1b1d1bb0 !important;
}

.swal2-icon.swal2-warning {
    border-color: var(--main-color) !important;
    color: var(--main-color) !important;
}

.cdk-global-scrollblock {
    position: initial;
    width: initial;
    overflow: hidden;
}

.tooltip-custom {
    background-color: var(--main-color);
    font-size: 15px;
}

.mat-progress-bar-fill::after {
    background-color: var(--main-color);
}

/* remove animation and the dots*/

.mat-progress-bar-background {
    animation: none;
    background-color: var(--main-color);
    fill: var(--main-color);
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: var(--main-color);
}

.card-home-skills {
    padding: 0px;
    max-width: 250px;
    margin: 0 auto;
    float: none;
    min-height: 285px;
}

.section-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 30px;
    margin-bottom: 30px;
    height: auto;
    h3 {
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 26px;
        color: #3c4858;
    }
    h5 {
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 15px;
        color: #bbb;
    }
}

.SeeAll {
    text-align: right;
}

.flex-title {
    width: 100%;
    min-height: 120px;
    max-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 60px;
    h2 {
        text-transform: uppercase;
        font-size: 26px;
        font-weight: 500;
    }
    h3 {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 500;
        color: var(--main-color);
    }
}

.uppercase {
    text-transform: uppercase;
}

.fw-600 {
    font-weight: 600;
}

.text-primary {
    color: var(--main-color) $im;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-20 {
    font-size: 20px;
}

.fs-50 {
    font-size: 50px;
}

.courses-title {
    height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}

.courses-title-2-lines {
    height: 44px;
    line-height: 22px;
    @include line-truncate(2);
}

/* Width */

.w-5 {
    width: 5% $im;
}

.w-10 {
    width: 10% $im;
}

.w-15 {
    width: 15% $im;
}

.w-20 {
    width: 20% $im;
}

.w-30 {
    width: 30% $im;
}

.w-40 {
    width: 40% $im;
}

.w-60 {
    width: 60% $im;
}

.w-100 {
    width: 100% $im;
}

/* height */

.h-160 {
    height: 160px;
}

.h-400 {
    height: 400px;
}

.breadcrumb {
    background: #f4f4f4;
    margin-top: 22px;
}

.pointer {
    cursor: pointer;
}

.progress {
    height: 17px;
    border-radius: 30px;
    .progress-bar {
        background: var(--primary-color) !important;
    }
}

.feed-card {
    border-radius: 10px $im;
    box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) $im;
    -moz-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) $im;
    -webkit-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7) $im;
    border: none $im;
}

.to-end {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    background-color: rgba(21, 21, 21, 0.8);
    line-height: 34px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    color: #fff;
    padding-right: 49px;
    text-align: right;
}

.relative {
    position: relative;
}

.upload-image-banner {
    width: 100%;
    height: 187px;
    object-fit: cover;
    object-position: center;
}

.breadcrumb-item {
    a {
        color: #3c4858;
        &:hover {
            color: var(--main-color);
        }
    }
}

.p-channel {
    color: #3c4858 $im;
}

.progress-bar-orange {
    background: var(--main-color) $im;
}

.text-orange {
    color: var(--main-color) $im;
}

// .dropdown-menu li a:hover,
// .dropdown-menu li a:focus,
// .dropdown-menu li a:active {
//     background-color: var(--main-color) $im;
// }
.main-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 320px;
    color: #fff;
    overflow: hidden;
    padding: 72px 0 40px;
    .main-banner-bg {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 320px;
        border-radius: 0 0 50% 50%/0 0 100% 100%;
        transform: scaleX(1.7);
        z-index: -1;
    }
    .avatar {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        border: 4px solid var(--main-color);
    }
    h4 {
        font-size: 30px;
    }
    .icon {
        width: 60px;
        height: auto;
        // border-radius: 50%;
        // border: 4px solid var(--main-color);
    }
    .hours {
        margin: 15px 0;
        i {
            float: left;
            font-size: 30px;
            color: var(--main-color);
            margin-right: 10px;
        }
        h3 {
            float: left;
            margin-right: 10px;
        }
        .text-hours {
            float: left;
            margin: 6px 0 0;
            p {
                font-size: 10px;
                margin: 0;
                padding: 0;
                text-transform: uppercase;
                line-height: 1.1;
            }
        }
    }
}

.box-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #818182;
    text-align: center;
    margin-top: 52px;
    h3 {
        font-weight: 500;
        text-transform: uppercase;
        font-size: 1.6em;
    }
    p {
        font-size: 14px;
    }
}

.section {
    .title {
        padding-top: 52px;
        font-size: 1.4em;
        text-transform: uppercase;
    }
}

.progress {
    height: 17px;
    border-radius: 30px;
    .progress-bar {
        background: var(--main-color);
    }
}

.navigation {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 20px 20px 0;
    li {
        margin: 0 15px;
        background: var(--bg-btn);
        color: var(--text-btn);
        padding: 7px 15px;
        border-radius: 4px;
        cursor: pointer;
        text-transform: uppercase;
        &:hover {
            opacity: 0.8;
        }
    }
}

.card-trail-body {
    height: 260px;
}

.item-card-img {
    img {
        width: 90px !important;
        height: 90px;
        border-radius: 50%;
        margin: 15px auto 0;
        -o-object-fit: cover;
        object-fit: cover;
        border: 2px solid var(--main-color);
    }
}

.border-rounded {
    border-radius: 10px;
}

.card-trail-header {
    height: 180px;
    border-radius: 10px 10px 0 0 !important;
}

.card-style-2,
.carousel-item-card {
    margin: 6px 9px;
    width: 234px !important;
}

.carousel-item-card {
    h5, h5.card-title {
        width: 100%;
        font-size: 16px;
        height: 55px !important;
        margin-top: -10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 1.2;
    }

    p, p.card-text {
        font-size: 14px;
        height: 50px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 1.2;
    }

    .mt-4 {
        margin-top: 10px !important;
    }

    .btn-round {
        margin-top: 10px;
    }
}

.compress-training {
    width: 1000px !important;
    margin: 0 auto;
}

.btn-opacity-hover {
    opacity: 0.8;
}

.btn-opacity-hover:hover {
    opacity: 1;
}

.card-events-datetime {
    font-size: 12px;
    // margin-bottom: 12px;
}

.next-lives-container {
    margin-bottom: -40px;
}

.compress-portal .header-container .compress {
    width: 100%;
}

.component-title {
    font-weight: bold;
}

.parallax-effect {
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-image-effect {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #000;
    opacity: 0;
}

.p-relative {
    position: relative;
}

.bb-black-3 {
    border-bottom: 3px solid #222;
    font-weight: 500;
    text-transform: uppercase;
}

.curriculum {
    padding: 15px;
}

.fc-blue {
    color: #5070ca;
}

.fc-grey {
    color: #888;
}

.fc-white {
    color: #fff;
}

.fc-green {
    color: #4caf50;
}

.socials {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    li {
        margin-right: 10px;
        margin-left: 10px;
        list-style: none;
        &:last-child {
            margin-right: 0;
        }
        a {
            color: var(--link-footer) $im;
            &:hover {
                opacity: 0.5;
            }
        }
    }
}

.form-feed {
    border-radius: 0 !important;
}

.grid-card-rounded {
    .form-feed {
        border-radius: 8px !important;
    }
}

@media (max-width: 767px) {
    .component-title {
        font-size: 24px !important;
    }
    .component-subtitle {
        font-size: 18px !important;
    }
    .compress-training {
        width: 100% !important;
        padding: 25px;
    }
    .SeeAll {
        text-align: center $im;
        margin-bottom: 10px;
    }
    .flex-title {
        h2 {
            font-size: 22px;
        }
        h3 {
            font-size: 18px;
        }
    }
    .card-style-2,
    .carousel-item-card {
        margin: 6px 25px;
        width: 275px !important;
    }

    .card-style-2,
    .carousel-item-card {
        margin: 6px 9px;
        width: 234px !important;

        h5 {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }

        p {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            color: #777;
        }
    }
    
    // Isso não deve ser utilizado, pois atrapalha o bom funcionamento do mat-menu-panel (drop-down)
    // .cdk-overlay-pane {
    //     width: 100vw !important;
    //     height: 100vh !important;
    // }
}

@media (min-width: 1366px) {
}

@media (min-width: 1900px) {
}

#general-search-container {
    margin-top: 2rem;
    .flex-column {
        width: 100%;
        min-height: 120px;
        max-height: 800px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.simple-notification-wrapper {
    z-index: 90000 !important;
}

.header-flix-content > p {
    @include line-truncate(4);
}

.banner-flix-content h2 {
    @include line-truncate(2);
}

.banner-flix-content p {
    @include line-truncate(3);
}

.banner-lead {
    .banner-lead-container {
        width: 96%;
        margin: 0 auto;
        .row {
            display: flex;
            align-items: center;
            height: 100%;
            .component-title,
            .component-subtitle {
                margin-bottom: 10px;
            }
        }
    }
}

.lead-form {
    background: #fff;
    .lead-form-title {
        background: #000;
        color: #fff;
        padding: 30px;
        font-weight: bold;
    }
    .lead-form-control {
        padding: 30px;
    }
}

.design-header-header {
    margin-bottom: -7px;
}

.mentores__avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 100%;
}

.full-calender-mentores {
    .fc-day {
        background-color: #eee !important;
    }

    .fc-bgevent {
        opacity: 0.7 !important;
    }

    .fc-unthemed th,
    .fc-unthemed td,
    .fc-unthemed thead,
    .fc-unthemed tbody,
    .fc-unthemed .fc-divider,
    .fc-unthemed .fc-row,
    .fc-unthemed .fc-content,
    .fc-unthemed .fc-popover,
    .fc-unthemed .fc-list-view,
    .fc-unthemed .fc-list-heading td {
        border-color: #aaa;
    }
}
