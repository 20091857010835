:root {
    --white: #fff;
    --hard-light: #f6f6f6;
    --lightest: #dcddde;
    --light: #b9bbbe;
    --gray: #6e7279;
    --medium: #36393f;
    --dark: #2f3136;
    --darkest: #292b2f;
    --black: #202225;
    --green: #2ecc61;
    --blue: #7289bf;
    --magenta: #e31e5d;
    --red: #f04747;
    --warning: #faa61a;
    --divider: #42454a;

    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}

