
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .page-link {
    color: $primary-color;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .active {
    background: $primary-color;
    color: #fff;
  }
}

.breadcrumb {
  font-size: 14px;
}
