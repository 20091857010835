.panel-journey .mat-expansion-panel-header-title,
.panel-journey .mat-expansion-panel-header-description {
  justify-content: flex-start;
  align-items: center;
}

.panel-journey {

  .mat-expansion-panel-header.mat-expanded {
    opacity: 1;
  }

  .mat-expansion-panel-header {
    opacity: 0.5;
  }

  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    border: none;
    margin-bottom: 20px;
    padding: 10px 0 !important;
  }


  .disable_ripple:hover {
    background: transparent !important;
  }

  .mat-expansion-panel-header {
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: #707070;
      i {
        color: #fff;
        font-size: 16px;
      }
    }
    h4 {
      font-size: 14px;
      color: #707070;
      text-transform: uppercase;
    }
  }

  .card-journey-item {
    .cover {
      width: 100%;
      height: 180px;
      border-radius: 0 !important;
      object-fit: cover;
    }
    .reward {
      p {
        color: #777;
        text-transform: uppercase;
      }
      h4 {
        font-weight: 600;
      }
    }

  }

  .card-list {
    border-radius: 10px;
    box-shadow: 1px 1px 10px rgba(201,191,187,0.7);
    -moz-box-shadow: 1px 1px 10px rgba(201,191,187,0.7);
    -webkit-box-shadow: 1px 1px 10px rgba(201,191,187,0.7);
    .list-group-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .fa-circle-thin, .fa-check-circle, .fa-lock {
        margin-top: 3px;
        font-size: 22px;
      }
      .fa-circle-thin, .fa-check-circle {
        color: var(--main-color);
      }
      .fa-lock {
        color: #ddd;
        margin-right: 3px;
      }
    }
  }

}

.journey-cover {
  width: 100%;
  height: auto;
  object-fit: contain;
  padding: 30px;
}


.owl-journey {
  position: relative;
  z-index: 1;
  height: 200px;
  // margin-bottom: 100px !important;
  margin-left: -7px;

  ::ng-deep .owl-item.active.center {
    margin: 6px 0 6px 0;
    z-index: 1;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7);
      -moz-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7);
      -webkit-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7);
      width: 300px;
      height: 200px;
      opacity: 1;
      transition: all .3s;
      background: #fff;
      border-radius: 10px;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .completed {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 10px;
          right: 10px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: var(--main-color);
          color: #fff;
          font-size: 14px;
        }

        img {
          width: 100px;
          height: 100px;
          margin-top: 15px;
        }

        .title {
          font-size: 24px;
          color: #414141;
          margin: 10px 0 0;
        }
      }
    }
  }

  ::ng-deep .owl-item {
    margin-top: 25px;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7);
      -moz-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7);
      -webkit-box-shadow: 1px 1px 10px rgba(201, 191, 187, 0.7);
      width: 250px;
      height: 160px;
      color: #414141;
      transition: all .3s;
      background: #fff;
      border-radius: 10px;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .completed {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 10px;
          right: 70px;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: var(--main-color);
          color: #fff;
          font-size: 12px;
        }

        img {
          margin-top: 15px;
          width: 80px;
          height: 80px;
        }

        .title {
          font-size: 16px;
          color: #414141;
          margin: 10px 0 0;
        }
      }
    }
  }
}

.img-cover {
  object-fit: cover;
}

// .customNavigation {
//   .prev {
//     margin-top: 30px;
//   }

//   .next {
//     margin-top: 30px;
//   }
// }


.owl-journey-2 {
  position: relative;
  z-index: 1;
  height: auto;

  .item {
    margin: 6px 8px;

    .card {
      margin-bottom: 0;
    }

    .cover {
      position: relative;
      z-index: 10;
      width: 100%;
      height: 140px !important;
      background-color: var(--main-color);
      object-fit: cover;
      object-position: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .completed {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background-color: rgba(47, 197, 0, 0.5);
      position: absolute;
      z-index: 20;
      top: 8px;
      right: 8px;
      text-align: right;
      color: #fff;
      border-radius: 50%;
      font-size: 12px;
    }

    .title {
      font-size: 16px;
      color: #414141;
    }


  }
}


@media (max-width: 767px) {

  .owl-journey {
    margin-left: 0;

    ::ng-deep .owl-item.active.center {
      .item {
        width: calc(100% - 15px);
        margin: 0 10px;
        padding: 5px;

        a {
          img {
            width: 80px;
            height: 80px;
            margin-top: 10px;
          }

          .title {
            font-size: 20px;
          }
        }
      }
    }
  }

}

.section-custom-header {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;

  .component-title {
    max-width: 70%;
  }

  .progress {
    width: 30px;
    height: 30px;
    background: none;
    position: relative;
  }

  .progress::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid #eee;
    position: absolute;
    top: 0;
    left: 0;
  }

  .progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .progress .progress-left {
    left: 0;
  }

  .progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border: 3px solid var(--main-color);
    position: absolute;
    top: 0;
  }

  .progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }

  .progress .progress-right {
    right: 0;
  }

  .progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }

  .progress .progress-value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }


  .panel-journey {
   .mat-expansion-panel-header {
      h4 {
        font-size: 14px;
      }
    }
  }

}
