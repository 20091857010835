.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 200px;
  margin-bottom: 0;
  text-align: center;

  h2 {
    color: #3c4858;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 15px;

    span {
      color: $primary-color;
    }
  }

  h4 {
    font-size: 18px;
    color: #9e9e9e;
  }
}

.banner-home {
  display: flex;
  flex-direction: column;
  // align-items: center;
  align-items: initial;
  justify-content: space-between;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 330px;

  .content-banner {
    // width: 800px;
    width: 100%;
  }

  .emblem {
    float: left;
    width: 110px;
    height: 150px;
    margin-right: 40px;
  }

  h2 {
    color: #fff;
    font-weight: 600;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
    font-size: 2.27em;
    line-height: 1.45;
    margin-top: 2px;
  }
}

.banner-overlay {
  // width: 80px !important;
  // height: 80px !important;
  // max-width: 80px !important;
  // max-height: 80px !important;

  .material-icons {
    font-size: 16px !important;
  }
}

.banner-school {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 200px;
  margin-bottom: 0;
  text-align: center;

  h2 {
    font-size: 2em;
    font-weight: 700;
    margin-bottom: 15px;

    span {
      color: $primary-color;
    }
  }

  h4 {
    font-size: 18px;
    color: #9e9e9e;
  }
}

.banner-my-page,
.banner-my-store {
  display: flex;
  align-items: center;
  width: 850px;
  min-height: 250px;
  height: auto;
  margin: 0 auto;
  clear: both;

  .emblem {
    position: absolute;
    right: 10%;
    top: 5%;

    i {
      font-size: 18px;
      margin-right: 5px;
    }

    span {
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  .avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-right: 50px;
    object-fit: cover;
  }

  .profile {
    width: 650px;
    height: auto;
    color: #515151;
    overflow: hidden;

    .name {
      float: left;

      h3 {
        display: inline-block;
        margin: 0 20px 0 0;
        padding: 0;
      }
    }

    p {
      font-size: 14px;
      line-height: 1.5;
    }

    .edit {
      position: relative;
      color: #bbb $im;
      top: 10px;

      &:hover {
        color: #111 $im;
      }
    }

    ul {
      margin: 25px 0;
      padding: 0;

      li {
        float: left;
        margin-right: 40px;
        font-size: 16px;
        list-style: none;

        &:last-child {
          float: none;
        }

        span {
          font-weight: 500;
          color: #777;
        }
      }
    }
  }
}

.header-flix {
  position: relative;
  display: flex;

  .header-flix-content {
    position: absolute;
    width: 55%;
    left: 0;
    padding: 5%;
    z-index: 9;
  }

  .header-flix-image {
    width: 100%;
    max-height: 50vw;
    height: 80vh;

    img {
      width: 80%;
      margin-left: 20%;
      height: 100%;
      object-fit: cover;
    }

    &:before {
      display: block;
      content: "";
      position: absolute;
      width: calc(100% + 1px);
      height: calc(100% + 1px);
      z-index: 1;
      background: linear-gradient(180deg, rgba(31, 31, 31, 0) 35%, #1f1f1f),
        linear-gradient(180deg, rgba(0, 0, 0, 0.5) 5%, transparent 20%),
        linear-gradient(270deg, transparent 30%, #000 80%);
    }
  }

  .content-banner {
    width: 800px;

    .emblem {
      float: left;
      width: 110px;
      height: 150px;
      margin-right: 40px;
    }

    h2 {
      color: #fff;
      font-weight: 600;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
      font-size: 2.27em;
      line-height: 1.45;
      margin-top: 2px;
    }
  }
}

.banner-flix {
  display: flex;
  align-items: center;
  height: calc(100% - 1px);
  margin-left: 0;
  margin-right: 0;
  position: relative;
  background: #000;
  color: #fff;

  .banner-flix-content {
    width: 45%;
    padding: 5%;
  }

  .banner-flix-image {
    width: 55%;
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% - 1px);
    margin-bottom: 0;

    .banner-flix-image__featured-background {
      display: block;
      content: "";
      position: absolute;
      width: calc(100% + 1px);
      height: calc(100% + 1px);
      z-index: 1;
      background: linear-gradient(90deg, #000, transparent 40%, transparent);
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

// .banner-casarotto {
//   margin-top: -56px;
// }

.carousel-stage .card-live {
  max-width: 960px;
  background: white;
}

@media (max-width: 767px) {
  .banner {
    min-height: 100px;
    height: auto;
    margin-bottom: 20px;

    h2 {
      font-size: 28px;

      span {
        white-space: pre;
        margin-top: 10px;
      }
    }

    h4 {
      font-size: 18px;
      color: #9e9e9e;
    }
  }

  .banner-school {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 200px;
    margin-bottom: 0;
    text-align: center;

    h2 {
      font-size: 1.5em;
      font-weight: 700;
      margin-bottom: 15px;

      span {
        color: $primary-color;
      }
    }

    h4 {
      font-size: 18px;
      color: #9e9e9e;
    }
  }

  .banner-my-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
    min-height: 200px;
    height: auto;
    padding: 0 20px;

    .emblem {
      position: absolute;
      right: 8%;
      top: 0;
      padding: 3px 10px;
      background: #fff;
      color: $primary-color;

      i {
        font-size: 16px;
        margin-right: 5px;
      }

      span {
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .avatar {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-top: 0;
      margin-right: 0;
    }

    .profile {
      width: auto;
      height: auto;
      padding: 40px 0;

      .name {
        float: none;
        display: inline-block;

        h3 {
          display: inline-block;
          margin: 0 10px 0 0;
          padding: 0;
        }
      }

      .edit {
        position: static;
        color: #bbb $im;
        top: -3px;
      }

      ul {
        margin: 25px 0;
        padding: 0;

        li {
          float: none;
          display: inline-block;
          font-size: 16px;
          list-style: none;
          margin-right: 15px;

          &:last-child {
            float: none;
            margin-right: 0;
          }

          span {
            font-weight: 500;
            color: #777;
          }
        }
      }
    }
  }

  .banner-my-store {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: auto;
    min-height: 200px;
    height: auto;
    padding: 0 20px;

    .emblem {
      position: absolute;
      top: 15px;
      right: 0;
      padding: 3px 10px;
      background: #fff;
      color: $primary-color;

      i {
        font-size: 16px;
        margin-right: 5px;
      }

      span {
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .avatar {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      margin-top: 50px;
      margin-right: 0;
      margin-bottom: 30px;
    }

    .profile {
      width: auto;
      height: auto;
      padding: 0 0 40px;

      .name {
        float: none;
        display: inline-block;

        h3 {
          display: inline-block;
          margin: 0 10px 0 0;
          padding: 0;
        }
      }

      .edit {
        position: static;
        color: #bbb $im;
        top: -3px;
      }

      ul {
        margin: 25px 0;
        padding: 0;

        li {
          float: none;
          display: inline-block;
          font-size: 16px;
          list-style: none;
          margin-right: 15px;

          &:last-child {
            float: none;
            margin-right: 0;
          }

          span {
            font-weight: 500;
            color: #777;
          }
        }
      }
    }
  }

  .banner-home {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;

    .content-banner {
      height: 100%;

      h2 {
        font-size: 1.2em !important;
        margin: 10px 0;
      }
    }

    .emblem {
      display: none;
    }
  }

  .banner-flix {
    display: block;

    .banner-flix-content {
      width: 100%;
      padding: 5% 0;
      text-align: center;
    }

    .banner-flix-image {
      width: 100%;
      position: relative;

      &:before {
        display: block;
      }
    }
  }
}

.banner-flix-content h2 {
  @include line-truncate(2);
}

.banner-flix-content p {
  @include line-truncate(3);
}

.banner-data {
  width: 100%;
  min-height: 90px;

  .data-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 90px;
    color: #fff;

    i {
      font-size: 40px;
      margin-right: 15px;
    }

    .text {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding-top: 5px;

      p {
        line-height: 1.2;
      }

      .title {
        font-size: 16px;
        font-weight: 500;
      }

      .description {
        font-size: 18px;
      }
    }
  }
}

.settings-flex {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 90px;

  i {
    font-size: 40px;
    margin-right: 15px;
  }
}

.carousel-stage {
  .card-live {
    max-width: 1000px;
    background: white;
  }

  .owl-dots {
    top: 10px;
  }
}

.header-banner-text-content {
  width: 50%;
  padding: 5%;
  position: absolute;
}

.header-banner-text-content-left-top {
  top: 0;
  left: 0;
  transform: translate(0, 0);
}

.header-banner-text-content-left-center {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.header-banner-text-content-left-bottom {
  top: 100%;
  left: 0;
  transform: translate(0%, -100%);
}

.header-banner-text-content-center-top {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.header-banner-text-content-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header-banner-text-content-center-bottom {
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.header-banner-text-content-right-top {
  top: 0;
  right: 0;
  transform: translate(0, 0);
}

.header-banner-text-content-right-center {
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
}

.header-banner-text-content-right-bottom {
  top: 100%;
  left: 100%;
  transform: translate(-100%, -100%);
}

.bg-btn-primary {
  background: #d0d3bd !important;
}

.text-btn-primary {
  color: #302d2a !important;
}
