// @import '~bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "imports/variables";
@import "imports/mixins";
@import "imports/resets";
@import "imports/chat";
@import "imports/accordions";
@import "imports/buttons";
@import "imports/banners";
@import "imports/cards";
@import "imports/carousels";
@import "imports/channels";
@import "imports/custom";
@import "imports/developers";
@import "imports/feeds";
@import "imports/footers";
@import "imports/forms";
@import "imports/custom-icon";
@import "imports/journey";
@import "imports/lists";
@import "imports/material";
@import "imports/messenger";
@import "imports/modal";
@import "imports/pagination";
@import "imports/payment";
@import "imports/navbar";
@import "imports/school";
@import "imports/sidemenu";
@import "imports/dynamic-sidebar";
@import "imports/tables";
@import "imports/timeline";
@import "imports/wrappers";
@import "imports/my-page";
@import "imports/responsive";
@import "imports/labFinanceiro";
@import "imports/grid-card-rounded";
@import "imports/grid-card-shadow";



.supplementary-body {
  max-height: 50vh !important;
  overflow-y: scroll;
  margin: 0 !important;
}

.clearfix {
  clear: both;
}

p {
  margin-bottom: 0;
}

a {
  color: $primary-color;

  &:hover {
    color: $primary-color-hover;
  }

  &:focus {
    color: $primary-color-hover;
  }
}

a,
a:hover,
a:focus {
  text-decoration: none;
  transition: all .3s;
  cursor: pointer;
}

.navbar-custom .navbar-nav .dropdown-menu .dropdown-menu-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 20px;
  padding: 15px;
  background: var(--bg-navbar);
  color: var(--link-navbar);
  font-size: 13px;
}

.navbar-custom .navbar-nav .dropdown-menu {
  overflow: hidden;
  box-shadow: none;
}

// .navbar-custom .navbar-nav .nav-link .badge {
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   min-width: 15px;
//   min-height: 15px;
//   width: auto;
//   height: auto;
//   font-size: 10px;
//   position: relative;
//   left: -10px;
//   font-family: sans-serif;
//   top: -12px;
//   background: $secundary-color;
//   color: #fff;
// }

.navbar .container {
  max-width: 100% !important;
}

.progress-number {
  top: 25% !important;
  font-size: 1.7em !important;
  text-align: center;
  left: initial !important;
  width: 100%;
}

div#blank-content {
  line-height: 30px;
}

.blank-icon {
  font-size: 4em;
  background-color: #f5f5f5;
  width: 150px;
  border-radius: 100%;
  height: 150px;
  vertical-align: middle;
  line-height: 144px;
  display: inline-block;
  margin-bottom: 11px;
}

.blank-icon .fa {
  display: inline;
  color: #a7a7a7;
  vertical-align: middle;
}

.blank-title {
  font-size: 1em;
  font-weight: bold;
  color: #666;
}

.blank-description {
  color: #a2a2a2;
  font-size: 1.2em;
}

.iziToast {
  background: #FFF;
}

.iziToast>.iziToast-body {
  padding: 0;
}

.iziToast>.iziToast-progressbar>div {
  background: $primary-color;
}
.iziToast:after {
  box-shadow: 0 7px 20px -7px rgba(0,0,0,.2), 0 0 5px rgba(0,0,0,.1), 0 8px 8px -5px rgba(0,0,0,.25);
}


.capitalize {
  text-transform: capitalize;
}
