
.banner-payment {
  display: flex;
  align-items: center;
  width: 100%;
  height: 300px;
  padding: 30px 0;
  background: #3e484e;
  color: #fff;

  .banner-payment-bg {
    display: block;
    position: absolute;
    z-index: 0 !important;
    width: 100%;
    height: calc(300px - 100px);
    -webkit-filter: blur(30px) brightness(80%);
    filter: blur(30px) brightness(80%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .container {
    z-index: 1;
  }

  .title {
    font-size: 2em;
    margin-bottom: 15px;
  }

  .description {
    font-size: 1em;
    margin-bottom: 15px;
  }

  .author {
    margin-bottom: 15px;
  }
}

.what {
  width: 100%;
  min-height: 200px;
  height: auto;
  margin: 30px 0;
  padding: 15px;
  background: #f7f7f7;
  border: 1px solid #eee;
  border-radius: 4px;
  .title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .col-sm-6 {
    margin: 10px 0;
    .fa {
      margin-right: 15px;
      color: #5cb85c;
    }
  }
}

.sections {
  margin: 0 0 30px;
  .title {
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .description {
    font-size: .9em;
  }

  .time {
    font-size: .9em;
    font-weight: 500;
    margin-top: 10px;
  }

  .mat-expansion-panel {
    box-shadow: none;
    background: #f7f7f7;
    border: 1px solid #eee;
  }

  .mat-expansion-panel-header-description {
    flex-grow: 0 $im;
  }

  .list-requirements {
    margin-left: 15px;
    margin-bottom: 0;

    li {
      font-size: .9em;
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }

  }

  .author {

    .username {
      font-size: 1.3em;
      font-weight: 500;
      margin-bottom: 15px;
      color: $primary-color;
    }

    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }

    .list-curriculum {
      list-style: none;

      li {
        font-size: .8em;
        margin-bottom: 3px;
        i {
          margin-right: 10px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

    }

  }

  .rating {

    .points {
      font-size: 4.3em;
    }

    .stars {
      width: 100%;
      height: auto;
      color: #aaa;

      i {
        margin-right: 4px;
      }

      &:last-child {
        margin-right: 0;
      }

    }

  }

  .percent {

    margin-top: 10px;

    .progress {
      height: 10px;
      margin-top: 8px;

      .progress-bar {
        background: $primary-color;
      }

    }

  }

  .comments {
    padding: 20px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;

    .avatar {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }

    .username {
      font-size: 1em;
      font-weight: 500;
    }

    .time {
      font-size: .8em;
      margin: 0;
    }

    .stars {

      i {
        color: #ddd;
        margin-right: 5px;
      }

    }

    .text {
      font-size: .9em;

      i {
        font-size: 1.2em;
        margin-right: 10px;
      }
    }

  }

  .card-curso {
    .cover {
      width: 100%;
      height: 140px;
      object-fit: cover;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    .card-body {
      h4 {
        font-size: 1.2em;
        font-weight: 500;
      }
      p {
        margin-top: 0;
        font-size: .9em;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .preco {
        font-size: 1.7em;
        margin-top: 15px;
      }

    }
  }

  .checked {
    color: #ffa500 $im;
  }

}

.col-sale {
  position: fixed;
  top: 156px;
  z-index: 2;

  .card {
    background: #f7f7f7;
    border: none;
    border-radius: 0;

    .cover-desktop {
      width: auto;
      height: 200px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      transition: height 0.5s;
      object-fit: cover;
    }

    .card-body {
      margin-top: 0;
      padding-top: 0;

      .box-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 45px;

        .price {
          font-size: 2em;
          font-weight: 500;
        }

        .mini-price {
          font-size: 1em;
          font-weight: 500;
          color: #aaa;
          text-decoration: none;
        }
      }

      .discount {
        color: #aaa;
      }

      .includes {
        p {
          font-size: 0.9em;
        }
        ul {
          list-style: none;
          margin-top: 10px;
          li {
            font-size: 0.9em;
            color: #aaa;
          }
        }
      }
    }
  }
}


@media (max-width: 767px) {

  .payment {

    .banner-payment {
      padding-left: 15px;
      padding-right: 15px;
      height: auto;

      .title {
        font-size: 1.5em;
      }
    }

    .author {

      .avatar {
        float: left;
        width: 90px;
        height: 90px;
        margin-right: 15px;
        margin-bottom: 15px;
        object-fit: cover;
      }

      .list-curriculum {
        margin-top: 5px;
      }

    }

    .rating {
      text-align: center;

      .points {
        font-size: 4em;
      }

      .stars {
        width: 100%;
        height: auto;
        color: #aaa;

        i {
          margin-right: 8px;
          font-size: 1.5em;
        }

        &:last-child {
          margin-right: 0;
        }

      }

    }

    .percent {

      margin-top: 20px;

      .progress {
        height: 7px;
      }

      .stars {

        i {
          font-size: 1em;
        }

      }

    }

    .comments {

      .col-12 {
        margin-top: 15px;
      }

    }

    .col-sale {
      margin-top: 50px;
      position: static;
      top: 0;
      z-index: 2;

      .card {
        box-shadow: none;
        margin-bottom: 0;
        border-radius: 0;
        .card-body {
          padding-top: 15px;
          padding-bottom: 0;
        }
        .cover-desktop {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 0;
        }
      }
     }

  }

}

.sticky-button {
  width: 100%;
  height: auto;
  background: #fafafa;
  position: sticky;
  top: 0;
  padding-top: 15px;
  z-index: 2;
  .btn {
    margin-bottom: 0;
  }
}

// .box-author {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   min-height: 120px;
//   height: auto;
//   .avatar {
//     img {
//       width: 100px;
//       height: 100px;
//       border-radius: 50%;
//       object-fit: cover;
//     }
//   }
//   .description {
//     width: calc(100% - 200px);
//     font-size: .9em;
//   }
// }
// .box-rating {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   text-align: center;
//   height: 150px;
//   .points {
//     width: 150px;
//     height: 100%;
//     .percent {
//       font-size: 3em;
//     }
//     .stars {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       font-size: 1.5em;
//       i {
//         color: #ddd;
//       }
//       .checked {
//         color: #ffa500;
//       }
//     }
//     .text {
//       margin-top: 15px;
//       font-size: .9em;
//     }
//   }
//   .details {
//     width: calc(100% - 200px);
//     height: 100%;
//     .col-sm-8 {
//       padding: 3px 0 0;
//       height: 32px;
//     }
//     .progress {
//       height: 10px;
//     }
//     .stars {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       color: #ddd;
//     }
//     .checked {
//       color: #ffa500;
//     }
//     .progress-bar {
//       background: $primary-color;
//     }
//   }
// }


// mat-expansion-panel ng-tns-c2-0 mat-expanded mat-expansion-panel-spacing
