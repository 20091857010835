/* Generated by Glyphter (http://www.glyphter.com) on  Fri May 25 2018*/
@font-face {
    font-family: 'custom icon';
    src: url('../../fonts/custom-icon.eot');
    src: url('../../fonts/custom-icon.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/custom-icon.woff') format('woff'),
         url('../../fonts/custom-icon.ttf') format('truetype'),
         url('../../fonts/custom-icon.svg#custom-icon') format('svg');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'custom icon';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.icon-decoracao:before{content:'\0041';}
.icon-festa:before{content:'\0042';}
.icon-infantil:before{content:'\0043';}
.icon-moda:before{content:'\0044';}
.icon-papelaria:before{content:'\0045';}
.icon-sob_medida:before{content:'\0046';}
.icon-tecnicas:before{content:'\0047';}