

.portal-footer {
  width: 100%;
  height: auto;
  background: #fff;
  padding: 30px 0 20px;
  margin-top: 20px;
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.1);

  h4 {
    font-size: 1.1em;
    color: $primary-color;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
  }
  p {
    font-size: 0.9em;
    color: $primary-color;
  }
  hr {
    border-top: 1px dashed $primary-color;
    border-bottom: transparent;
    color: $primary-color;
    background-color: transparent;
    height: 1px;
  }
  .logo {
    width: auto;
    height: 30px;
  }
}

.menu-footer {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin: 5px 0;
    &:first-child {
      margin: 0;
    }
    a {
      line-height: 1.5;
      font-size: 1em;
      color: $primary-color;
      &:hover {
        color: $primary-color-hover;
      }
    }
  }
}

.market-footer {
  width: 100%;
  height: auto;
  background: #fff;
  padding: 30px 0 20px;
  margin-top: 20px;
  box-shadow: 3px 0 10px rgba(0, 0, 0, 0.1);
  .title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .socials {
    li {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: block $im;
        color:#fff;
      }
    }
    .facebook {
      background: #5278c2;
    }
    .linkedin {
      background: #0e76a8;
    }
    .instagram {
      background: #8a5e4f;
    }
    .youtube {
      background: #ff0000;
    }
    .twitter {
      background: #1da1f2;
    }
  }
  .contact {
    list-style: none;
    li {
      line-height: 1.7;
      font-size: 14px;
      span {
        font-weight: 500;
      }
    }
  }
}

.mt-27 {
  margin-top: 27px;
}

.space-1,
.space-bottom-1 {
  padding-bottom: 2rem !important;
}

.space-1,
.space-top-1 {
  padding-top: 2rem !important;
}

.space-1,
.space-bottom-1 {
  padding-bottom: 2rem !important;
}

.space-2,
.space-top-2 {
  padding-top: 4rem !important;
}

.nav-x-sm .nav-link {
  padding-right: .25rem;
  padding-left: .25rem;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.nav-x-0 .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.btn-icon.btn-xs {
  font-size: 0.75rem;
  width: 1.75rem;
  height: 1.75rem;
}

.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  font-size: 0.8125rem;
  width: 2.6875rem;
  height: 2.6875rem;
}

.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  font-size: 1.25rem;
  width: 4.25rem;
  height: 4.25rem;
}

.btn-xs {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0;
}

/* light soft */
.btn-soft-light {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
  border-color: transparent;
}

.btn-soft-light:hover, .btn-soft-light:focus, .btn-soft-light:active, .btn-soft-light.active {
  color: #1e2022;
  background-color: #f7faff;
  box-shadow: 0 4px 11px rgba(247, 250, 255, 0.1);
}

.btn-soft-light.disabled, .btn-soft-light:disabled {
  color: #f7faff;
  background-color: rgba(247, 250, 255, 0.1);
  box-shadow: none;
}

.btn-soft-light:not(:disabled):not(.disabled):active, .btn-soft-light:not(:disabled):not(.disabled).active,
.show > .btn-soft-light.dropdown-toggle {
  color: #1e2022;
  background-color: #f7faff;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

@media (max-width: 767px) {

  .market-footer {
    .title {
      margin: 20px 0;
      font-weight: 600;
    }
    .socials {
      display: flex;
      justify-content: space-between;
      li {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        font-size: 1.3em;
      }
    }
  }

}

.footer {
  width: 100%;
  height: auto;
  background: var(--bg-footer);
  padding: 30px 0 25px;
  h4 {
    font-size: 1.1em;
    color: var(--link-footer);
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
  }
  p {
    font-size: 0.9em;
    color: var(--link-footer);
  }
  hr {
    border-top: 1px dashed var(--link-footer);
    border-bottom: transparent;
    color: var(--link-footer);
    background-color: transparent;
    height: 1px;
  }
}

.menu-footer {
  margin: 0;
  padding: 0;

  li {
    margin: 5px 0;
    &:first-child {
      margin: 0;
    }

    a {
      font-size: 1em;
      color: var(--link-footer);
      &:hover {
        color: var(--main-color);
      }
    }
  }
}

.footer-fluid {
  width: 100% !important;
  padding: 2% 5%;
}

.footer-ecommerce {


  .nav-item, .nav-link {
    margin: 0 !important;
    padding: 0 !important;
    color: var(--link-footer) !important;

    .fa {
      display: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
