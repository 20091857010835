html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    vertical-align:baseline;
    background:transparent;
}


article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
    display:block;
}

nav ul {
    list-style: none;
}

a {
  color: $primary-color;
  text-decoration: none !important;
  &:hover {
    color: $primary-color-hover;
  }
  &:focus {
    color: $primary-color-hover;
  }
}

a, a:hover, a:focus {
  text-decoration: none !important;
  transition: all .3s;
  cursor: pointer;
}

// RESETS BOOTSTRAP 4.3

/* Cards */

.card-header, .card-footer {
  background: transparent;
  border-color: #eee;
  h4 {
    font-size: 1.2em;
    text-transform: uppercase;
  }
}

.text-primary {
  color: $primary-color $im;
}

.clickable {
  cursor: pointer;
}

button.swal2-cancel.swal2-styled{
  border-radius: 30px;
  background-color: var(--primary-color) !important;
}
button.swal2-confirm.swal2-styled {
  border-radius: 30px;
}
/* Width */

.w-5 {
  width: 5% $im;
}

.w-10 {
  width: 10% $im;
}

.w-15 {
  width: 15% $im;
}

.w-20 {
  width: 20% $im;
}

.w-30 {
  width: 30% $im;
}

.w-40 {
  width: 40% $im;
}
.w-90 {
  width: 90% $im;
}
.w-100 {
  width: 100% $im;
}

.breadcrumb {
  background: #f4f4f4;
  margin-top: 22px;
}

.cursor {
  cursor: pointer;
}

.mt-10em {
  margin-top: 10em;
}
.block-ui-wrapper{
  background: rgba(183, 16, 58, 0.7803921568627451) !important;
}

.breadcrumb {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: transparent !important;
  padding: 0;

  .breadcrumb-item {
    &.active {
      color: #bbb;
      font-size: 13px;
    }

    a {
      color: var(--main-color);
      font-size: 13px;
    }
  }
}
